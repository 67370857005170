import styled from '@emotion/styled'

export const ButtonSection = styled.section<{ justifyContent: string }>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};
    padding: 18px 32px;
    gap: 8px;
    flex-wrap: wrap-reverse;
`
