import { PageContainer } from '../../../../styling'
import { ConfigurableListPageTemplate } from '../../../../configurable-list'
import { TOTEUMAT_LIST_ID } from '../../VuorotJaVapaatPage/Constants/VuorotJaVapaatConstants'

const ToteumatPage = (): JSX.Element => {
    return (
        <PageContainer>
            <ConfigurableListPageTemplate listId={TOTEUMAT_LIST_ID} />
        </PageContainer>
    )
}

export default ToteumatPage
