import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { ButtonWithMenu, IMenuItemProps } from '../../../generic-components'
import { deleteReportThunk, fetchListDataExportedToExcelThunk } from '../../Thunks/ConfigurableListThunks'
import {
    selectCanUserCreateReports,
    selectCanUserDeleteReports,
    selectDataSourceId,
    selectListReports,
} from '../../State/ConfigurableListSelectors'
import { IListViewReport } from '../../../configurable-list'
import { translate } from '../../../localization'
import CreateReportModal from '../CreateReportModal'
import { closeModalAction, ConfirmationDialog, openModalAction, selectIsModalOpen } from '../../../modal'
import EConfigurableListModal from '../../Types/EConfigurableListModal'
import { displaySuccessToaster } from '../../../notifications'
import { useQuery } from '../../../generic-utilities'
import { ERequestMethod } from '../../../rest-api'
import { compact, isEmpty } from 'lodash-es'
import { getTestId } from '../../../test'
import { useAppDispatch } from '../../../redux/hooks'

type Props = {
    listId: string
}

const MenuButton = ({
    listId,
    createReportModalId,
    canCreateReports,
}: {
    listId: string
    createReportModalId: string
    canCreateReports: boolean
}): JSX.Element => {
    const dispatch = useAppDispatch()

    const reports = useSelector((state: RootState) => selectListReports(state, listId))
    const canDeleteReports = useSelector((state: RootState) => selectCanUserDeleteReports(state, listId))

    const [deletingReport, setDeletingReport] = useState<IListViewReport | null>(null)

    const handleClick = async () => {
        await dispatch(fetchListDataExportedToExcelThunk(listId))
    }

    const handleReportClick = async (report?: IListViewReport) => {
        await dispatch(
            fetchListDataExportedToExcelThunk(listId, report?.GroupByParameters, report?.IncludeProperties, true)
        )
    }

    const handleCreateReportButtonClick = (): void => {
        dispatch(openModalAction(createReportModalId))
    }

    const deleteReport = async (): Promise<void> => {
        if (!deletingReport?.Id) {
            return
        }

        await dispatch(deleteReportThunk(deletingReport.Id, listId))

        setDeletingReport(null)
    }

    const reportItems = reports
        ? reports.map((report: IListViewReport) => ({
              label: report.Name,
              onClick: () => handleReportClick(report),
              secondaryAction: canDeleteReports && {
                  icon: 'delete_outline',
                  onClick: () => setDeletingReport(report),
              },
          }))
        : []

    const createNewReportOption = canCreateReports
        ? {
              label: translate('configurableList.reports.createNew'),
              onClick: handleCreateReportButtonClick,
          }
        : undefined

    const menuItems: IMenuItemProps[] = compact([
        { label: 'Kaikki tiedot', onClick: handleClick },
        ...reportItems,
        createNewReportOption,
    ])

    const testId = getTestId(['STC', 'BTN'], 'LIST_EXCEL')

    return (
        <>
            <ButtonWithMenu menuItemsData={menuItems} testId={testId} variant="outlined" large>
                Excel
            </ButtonWithMenu>

            {deletingReport && (
                <ConfirmationDialog
                    modalId={EConfigurableListModal.ConfirmDeleteReport}
                    confirmButtonText="modal-deletion-confirmation.ConfirmButtonLabel"
                    confirmationText={translate('configurableList.reports.confirmRemoveReport', {
                        name: deletingReport.Name,
                    })}
                    onSubmit={deleteReport}
                    onClose={() => setDeletingReport(null)}
                    modalOpen
                />
            )}
        </>
    )
}

const Content = ({ listId, dataSourceId }: { listId: string; dataSourceId: string }): JSX.Element => {
    const dispatch = useDispatch()

    const createReportModalId = `${EConfigurableListModal.CreateReport}-${listId}`

    const createReportModalOpen = useSelector((state: RootState) => selectIsModalOpen(state, createReportModalId))

    const isUserAllowedToCreateReports = useSelector((state: RootState) => selectCanUserCreateReports(state, listId))

    const { data: dataSourceProperties } = useQuery<{ ListData: { DisplayName: string }[] }>(
        'ViewEngine/DataSourceProperty/Search',
        ERequestMethod.POST,
        {
            Filters: { DataSourceIds: [dataSourceId] },
        },
        [dataSourceId]
    )

    const dataSources: any[] = dataSourceProperties?.ListData || []

    const groupingFieldOptions = dataSources.filter(({ CanBeGroupingTarget }) => CanBeGroupingTarget)
    const canCreateReports = !isEmpty(dataSources) && !isEmpty(groupingFieldOptions) && isUserAllowedToCreateReports

    const handleReportCreated = (reportName: string): void => {
        dispatch(closeModalAction(createReportModalId))
        dispatch(displaySuccessToaster(translate('configurableList.reports.reportCreated', { name: reportName })))
    }

    return (
        <>
            <MenuButton listId={listId} createReportModalId={createReportModalId} canCreateReports={canCreateReports} />

            {createReportModalOpen && (
                <CreateReportModal
                    listId={listId}
                    modalId={createReportModalId}
                    dataSourceProperties={dataSourceProperties}
                    onReportCreated={handleReportCreated}
                />
            )}
        </>
    )
}

const ListExcelButton = ({ listId }: Props): JSX.Element | null => {
    const dataSourceId = useSelector((state: RootState) => selectDataSourceId(state, listId))

    return dataSourceId ? <Content listId={listId} dataSourceId={dataSourceId} /> : null
}

export default ListExcelButton
