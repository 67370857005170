import * as React from 'react'

import ConfigurableListTemplate from '../ConfigurableListTemplate'

interface IConfigurableListPageTemplateProps extends React.ComponentProps<typeof ConfigurableListTemplate> {}

const ConfigurableListPageTemplate: React.FunctionComponent<IConfigurableListPageTemplateProps> = ({
    listIsVirtualized = true,
    shouldAddScrollListener = true,
    ...props
}) => (
    <ConfigurableListTemplate
        {...props}
        listIsVirtualized={listIsVirtualized}
        renderAggregatedData
        shouldAddScrollListener={shouldAddScrollListener}
        showExcelButton
        valuePickersVisibilityTogglable
    />
)

export default ConfigurableListPageTemplate
