import { PropsWithChildren } from 'react'

type Props = {
    flag: string
    fallback: JSX.Element | null
}

const featureFlags: Record<string, string | undefined> = {
    ACTION_BAR_RENEWAL: process.env.ACTION_BAR_RENEWAL,
}

const FeatureFlag = ({ flag, fallback = null, children }: PropsWithChildren<Props>): JSX.Element | null => {
    const isFeatureEnabled = featureFlags[flag] === 'true'

    return isFeatureEnabled ? <>{children}</> : fallback
}

export default FeatureFlag
