import * as React from 'react'
import { ComponentProps } from 'react'
import ButtonsWithValuePickers from '../configurable-list/components/ConfigurableListTemplate/ButtonsWithValuePickers'
import ConfigurableList from '../configurable-list'
import TValuePickerId from '../value-picker/types/TValuePickerId'
import { ISummaryLabel } from '../generic-components/SummaryLabel/SummaryLabels'

type TConfigurableListProps = ComponentProps<typeof ConfigurableList>

export interface IOwnProps extends Pick<TConfigurableListProps, 'shouldEmptyDataWhenUnmounting'> {
    accumulatedData?: React.ReactNode
    additionalButtons?: React.ReactNode
    isValuePickerGroupFullWidth?: boolean
    listId: string
    omitFilterIds?: TValuePickerId[]
    valuePickersVisibilityTogglable?: boolean
    renderButtonsAndFiltersWithinPaper?: boolean
    showExcelButton?: boolean
    onModalSubmitCallback?: () => void
}

interface IConfigurableListPageTemplateProps extends IOwnProps {
    summaryLabels?: ISummaryLabel[]
}

const LegacyActionBar = ({
    accumulatedData,
    additionalButtons,
    isValuePickerGroupFullWidth,
    listId,
    valuePickersVisibilityTogglable = false,
    renderButtonsAndFiltersWithinPaper = false,
    omitFilterIds = [],
    showExcelButton = false,
}: IConfigurableListPageTemplateProps): JSX.Element => {
    return (
        <ButtonsWithValuePickers
            accumulatedData={accumulatedData}
            additionalButtons={additionalButtons}
            isValuePickerGroupFullWidth={isValuePickerGroupFullWidth}
            listId={listId}
            omitFilterIds={omitFilterIds}
            renderWithinPaper={renderButtonsAndFiltersWithinPaper}
            showExcelButton={showExcelButton}
            valuePickersVisibilityTogglable={valuePickersVisibilityTogglable}
        />
    )
}

export default LegacyActionBar
