import { ChangeEvent, useRef } from 'react'
import styled from '@emotion/styled'
import { Button } from './Buttons/Button/'
import Icon from './Icon/'
import ReadOnlyText from './ReadOnlyText'
import { useAppTheme } from '../theme/'
import { translate } from '../localization'

export interface IFile {
    name: string
    content: unknown
}

type Props = {
    onChange: (file: IFile | null) => void
    value: IFile | null | undefined
    label: string
    displayStyle: 'default' | 'image' | 'pdf'
    isRequired?: boolean
}

const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const EmptyPhotoPicker = styled.div`
    margin-left: 40px;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    display: flex;
    padding-left: 3px;
    border: ${({ theme }) => `2px solid ${theme.colors.primaryTeal120}`};
    background: ${({ theme }) => theme.colors.primaryTeal11};

    &:hover {
        background: ${({ theme }) => theme.colors.primaryCyan60};
    }
`

const HiddenInput = styled.input`
    display: none;
`

const UploadedFile = ({ file, onDelete }: { file: IFile; onDelete: () => void }) => {
    return (
        <Row>
            <ReadOnlyText usage="bodyM">{file.name}</ReadOnlyText>

            <Button testId="Stc_Form_RemoveFileBtn" variant="text" startIcon={<Icon>close</Icon>} onClick={onDelete}>
                {translate('Poista')}
            </Button>
        </Row>
    )
}

const FileUpload = ({ onChange, value, displayStyle = 'default' }: Props): JSX.Element => {
    const theme = useAppTheme()

    const hiddenFileInput = useRef<HTMLInputElement | null>(null)

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click()
        }
    }

    const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (target && target.files && target.files.length > 0) {
            const content = await toBase64(target.files[0])
            const name = target.files[0].name

            onChange({ name: name, content: content })
        }
    }

    if (value) {
        return <UploadedFile file={value} onDelete={() => onChange(null)} />
    }

    const getAcceptedFileTypes = () => {
        switch (displayStyle) {
            case 'pdf':
                return '.pdf'
            case 'image':
                return 'image/jpeg, image/png'
            default:
                return
        }
    }

    const fileInput =
        displayStyle === 'image' ? (
            <EmptyPhotoPicker data-testid="Stc_Form_PhotoUpload" onClick={handleClick}>
                <ReadOnlyText usage="h5" rawColor={theme.colors.primaryTeal120}>
                    {translate('Lisää kuva')}
                </ReadOnlyText>
            </EmptyPhotoPicker>
        ) : (
            <Button testId="Stc_Form_AddFileBtn" variant="text" startIcon={<Icon>add</Icon>} onClick={handleClick}>
                {displayStyle === 'default' ? translate('Lisää tiedosto') : translate('uploadPDF')}
            </Button>
        )

    return (
        <>
            {fileInput}
            <HiddenInput type="file" accept={getAcceptedFileTypes()} onChange={handleChange} ref={hiddenFileInput} />
        </>
    )
}

export default FileUpload
