import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { IDropdownListOption } from './Dropdown'
import PopoverWithComponent from '../../PopoverWithComponent'
import SelectorOptions from './SelectorOptions'
import Icon from '../../Icon'
import ReadOnlyText from '../../ReadOnlyText'
import { translate } from '../../../localization'
import { useAppTheme } from '../../../theme'
import { isNil } from 'lodash-es'

export type Identifier = string | number

export interface ISelectorProps extends ISelectorComponentProps {
    viewComponentRenderer: (openFunc: (e: React.MouseEvent) => void) => React.ReactNode
    useFilter?: boolean
    useSearch?: boolean
    searchTerm?: string
    onChangeSearchTerm?: (val: string) => void | undefined
    showClearSelection?: boolean
    autoFocusFilter?: boolean
    isReadOnly?: boolean
}

export interface ISizes {
    XS?: boolean
    S?: boolean
    M?: boolean
    L?: boolean
    XL?: boolean
}

export interface ISelectorComponentProps extends ISizes {
    placeholder?: string
    disabled?: boolean
    onChange?: (val: number[]) => void
    onClick?: (e: React.MouseEvent) => void
    isMultiselect?: boolean
    value: number[]
    options: IDropdownListOption[]
    isLoading?: boolean
    errorText?: string
    itemOptionLabelFields?: string[]
    isReadOnly?: boolean
    fullWidth?: boolean
}

interface IContainerProps extends ISizes {
    disabled?: boolean
    error?: boolean
    isReadOnly?: boolean
    fullWidth?: boolean
}

const Container = styled.div<IContainerProps>`
    border: ${({ disabled, error, theme }) =>
        disabled
            ? `1px solid ${theme.componentExtensions.inputs.borderInputDisabled}`
            : error
            ? `2px solid ${theme.componentExtensions.inputs.borderInputError}`
            : `1px solid ${theme.componentExtensions.inputs.borderInputDefault}`};
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
    cursor: pointer;
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
    height: 40px;
    padding-left: 14px;
    align-items: center;
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    width: ${({ XS, S, M, L, XL, fullWidth }) => {
        if (fullWidth) {
            return '100%'
        }

        return XS ? '104px' : S ? '168px' : M ? '267px' : L ? '292px' : XL ? '352px' : '267px'
    }};

    &:hover {
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
    }

    &:focus {
        border-color: transparent;
        outline: 2px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputFocus};
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};
    }

    ${({ isReadOnly, theme }) =>
        isReadOnly &&
        `
            background-color: ${theme.componentExtensions.inputs.bgInputDisabled};
            border-color: ${theme.componentExtensions.border.primary};

            &:hover {
                background-color: ${theme.componentExtensions.inputs.bgInputDisabled};
            }

            &:focus {
                outline: none;
                border-color: ${theme.componentExtensions.border.primary};
                background-color: ${theme.componentExtensions.inputs.bgInputDisabled};
            }
        `}
`

const ErrorText = styled.span`
    color: ${({ theme }) => theme.componentExtensions.inputs.colorTextInputHelperError};
    font-family: 'Inter';
    font-size: 10px;
    line-height: 12px;
`

const StyledPopOverWithComponent = styled(PopoverWithComponent)`
    & .MuiPopover-paper {
        padding: 4px;
        max-width: 290px;
        width: 290px;
        overflow-y: auto;
        scrollbar-gutter: stable;
        ::-webkit-scrollbar {
            width: 8px;
            border-radius: 4px;
        }

        ::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.colors.neutralsGrey11};
            border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.colors.neutralsGrey30};
            border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${({ theme }) => theme.colors.neutralsGrey30};
        }
    }
`

const TextContainer = styled.div``

const DropdownIcon = styled(Icon)`
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.neutralsGrey100};
`

const getFilterName = (option: Record<string, any>, labelFields: string[]): string => {
    return labelFields
        .map((field) => option[field])
        .join(' ')
        .toLowerCase()
}

export const SelectorComponent = ({
    placeholder,
    disabled,
    errorText,
    onClick,
    value,
    options,
    isReadOnly,
    fullWidth,
}: ISelectorComponentProps): JSX.Element => {
    const { componentExtensions } = useAppTheme()

    const selectedValue = options.find((option) => option.Id === value[0])
    const showPlaceholder = !(value && selectedValue?.Name) && placeholder
    const showValue = value && selectedValue?.Name

    const selectedValueText = () => {
        if (value.length === 1) {
            return selectedValue?.Name
        }

        return `${selectedValue?.Name} +${value.length - 1}`
    }

    const handleOnClick = (event: React.MouseEvent) => {
        if (disabled || !onClick) {
            return
        }

        onClick(event)
    }

    return (
        <>
            <Container
                disabled={disabled}
                isReadOnly={isReadOnly}
                error={!!errorText}
                fullWidth={fullWidth}
                onClick={handleOnClick}
            >
                <TextContainer>
                    {showPlaceholder && (
                        <ReadOnlyText usage="dropdown_placeholder">{placeholder || translate('valitse')}</ReadOnlyText>
                    )}
                    {showValue && <ReadOnlyText usage="dropdown_selection">{selectedValueText()}</ReadOnlyText>}
                </TextContainer>
                <DropdownIcon
                    size={20}
                    color={
                        !disabled ? componentExtensions.icons.iconAction : componentExtensions.icons.iconDisabledLighter
                    }
                >
                    expand_more
                </DropdownIcon>
            </Container>
            {errorText && <ErrorText>{errorText}</ErrorText>}
        </>
    )
}

const Selector = (props: ISelectorProps): JSX.Element => {
    const {
        options,
        isMultiselect,
        viewComponentRenderer,
        onChange,
        value,
        useFilter,
        useSearch,
        searchTerm,
        onChangeSearchTerm,
        showClearSelection,
        itemOptionLabelFields,
        autoFocusFilter,
        isLoading,
        isReadOnly,
    } = props

    const [filterText, setFilterText] = useState<string>('')
    const [filteredOptions, setFilteredOptions] = useState<IDropdownListOption[]>([])

    useEffect(() => {
        const newOptions = useSearch
            ? options
            : options
                  .map((opt) => ({
                      ...opt,
                      filterName: getFilterName(opt, itemOptionLabelFields || ['Name']),
                  }))
                  .filter((option) => option?.filterName?.includes(filterText))

        setFilteredOptions(newOptions)
    }, [options, filterText, useSearch, itemOptionLabelFields])

    const handleClearSelection = () => {
        onChange && onChange([])
    }

    const handleOnClick = (option: IDropdownListOption, handleClose: () => void) => {
        if (isReadOnly) {
            return
        }

        if (!isMultiselect) {
            onChange && onChange([option.Id as number])
            handleClose()
        }

        if (isMultiselect && onChange) {
            const newValues = [...value]
            const index = newValues.findIndex((newValue) => newValue === option.Id)
            if (index > -1) {
                newValues.splice(index, 1)
            } else {
                newValues.push(option.Id as number)
            }

            onChange(newValues)
        }
    }

    const handleOnMultipleOptionsSelected = (selectedOptions: number[]) => {
        if (!onChange) {
            return
        }

        onChange(selectedOptions)
    }

    const handleOnChangeFilter = (filter: string) => {
        setFilterText(filter?.toLowerCase())
    }

    const showFilter = useFilter || options.length >= 15

    const onSearchTermChange = (filter: string) => {
        onChangeSearchTerm?.(filter)

        if (showFilter) {
            handleOnChangeFilter(filter)
        }
    }

    return (
        <StyledPopOverWithComponent
            {...props}
            openPopoverRenderer={(onMenuOpen) => viewComponentRenderer(onMenuOpen)}
            anchorOriginVertical={33}
            anchorOriginHorizontal={0}
        >
            {(handleClose) => (
                <SelectorOptions
                    options={filterText ? filteredOptions : options}
                    isMultiselect={isMultiselect}
                    onClick={(option) => handleOnClick(option as IDropdownListOption, handleClose)}
                    onMultipleOptionsSelected={handleOnMultipleOptionsSelected}
                    onClose={() => null}
                    selectedOptions={value}
                    useFilter={showFilter || !!useSearch}
                    onChangeFilter={onSearchTermChange}
                    onClearSelection={handleClearSelection}
                    filterText={useSearch ? searchTerm ?? '' : filterText}
                    showClearSelection={(isNil(showClearSelection) || showClearSelection) && !isReadOnly}
                    itemOptionLabelFields={itemOptionLabelFields}
                    autoFocusFilter={autoFocusFilter}
                    isLoading={isLoading}
                />
            )}
        </StyledPopOverWithComponent>
    )
}

export default Selector
