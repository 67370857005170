import styled from '@emotion/styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RadioButton, ReadOnlyText, SelectedItemExtras } from '../../generic-components'
import { useQuery } from '../../generic-utilities'
import { ERequestMethod } from '../../rest-api'
import { getTestId } from '../../test'
import { useAppTheme } from '../../theme'
import type { Identifier } from '../../types'
import { useValuePickerValuesAsQueryParameters } from '../../value-picker/Hooks/useValuePickerValuesAsQueryParameters'
import IValuePickerCommonOwnProps from '../../value-picker/types/IValuePickerCommonOwnProps'
import IValuePickerDependency from '../../value-picker/types/IValuePickerDependency'
import { getSideEffects } from '../utils'

export interface IRadioButtonListValuePickerProps extends IValuePickerCommonOwnProps<number> {
    DependsOn?: IValuePickerDependency[]
    OptionsEndpointUrl: string
    valuePickerId: string
    IsInline?: boolean
    hasTopDivider?: boolean
    hasBottomDivider?: boolean
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
`

const TitleContainer = styled.div`
    margin-bottom: 24px;
`

const RadioButtonContainer = styled.div<{ isInline: boolean; hasTopDivider?: boolean; hasBottomDivider?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
    column-gap: ${({ isInline }) => (isInline ? '12px' : '0px')};
    margin-top: ${({ hasTopDivider }) => hasTopDivider && '10px'};
`

const RadioButtonWrapper = styled.div<{ isInline: boolean }>`
    display: flex;
    align-items: center;
`

const SelectedItemExtrasContainer = styled.div`
    margin-top: 12px;
`

const TopDivider = styled.div`
    position: absolute;
    width: 450px;
    border-top: ${({ theme }) => `1px solid ${theme.componentExtensions.border.secondary}`};
`

const BottomDivider = styled.div`
    position: absolute;
    bottom: -8px;
    width: 450px;
    border-bottom: ${({ theme }) => `1px solid ${theme.componentExtensions.border.secondary}`};
`

const RadioButtonListValuePicker = ({
    label,
    value,
    onChange,
    DependsOn,
    OptionsEndpointUrl,
    valuePickerId,
    IsInline = false,
    hasTopDivider = false,
    hasBottomDivider = false,
}: IRadioButtonListValuePickerProps): JSX.Element => {
    const { colors } = useAppTheme()

    const queryParameters = useValuePickerValuesAsQueryParameters(DependsOn ?? [])

    const { data, isLoading } = useQuery<{ ListData: Identifier[] }>(
        OptionsEndpointUrl + queryParameters,
        ERequestMethod.POST,
        undefined,
        [queryParameters]
    )

    const [previousOption, setPreviousOption] = useState<Identifier | undefined>(undefined)

    const options: Identifier[] = useMemo(() => (isLoading || !data ? [] : data.ListData), [isLoading, data])

    const selectedOption = useMemo(
        () => options.find((x) => x.Id === value) ?? options.find(({ IsDefault }) => IsDefault),
        [options, value]
    )

    const selectedItemExtras = useMemo(
        () => options.filter((x) => x.Id === value && (x as Record<string, unknown>).hasOwnProperty('Extras')),
        [options, value]
    )

    const handleOnChange = useCallback(
        (id: number) => {
            const sideEffects = getSideEffects(id, options)

            onChange(id, sideEffects)
        },
        [onChange, options]
    )

    // Notify parent component when the selected option changes
    useEffect(() => {
        const hasOptionChanged = previousOption?.Id !== selectedOption?.Id

        if (hasOptionChanged && selectedOption) {
            setPreviousOption(selectedOption)
            handleOnChange(selectedOption.Id)
        }
    }, [handleOnChange, previousOption, selectedOption])

    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    return (
        <Container data-testid={testId}>
            {hasTopDivider && <TopDivider />}

            {label && (
                <TitleContainer>
                    <ReadOnlyText usage="h4" rawColor={colors.primaryTeal120}>
                        {label}
                    </ReadOnlyText>
                </TitleContainer>
            )}

            <RadioButtonContainer isInline={IsInline} hasTopDivider={hasTopDivider}>
                {options.map(({ Id, Name }) => (
                    <RadioButtonWrapper key={Id} isInline={IsInline}>
                        <RadioButton
                            checked={value === Id}
                            label={Name}
                            key={Id}
                            onValueSet={() => handleOnChange(Id)}
                        />
                    </RadioButtonWrapper>
                ))}
            </RadioButtonContainer>

            {selectedItemExtras.length > 0 && (
                <SelectedItemExtrasContainer>
                    <SelectedItemExtras items={selectedItemExtras} />
                </SelectedItemExtrasContainer>
            )}

            {hasBottomDivider && <BottomDivider />}
        </Container>
    )
}

export default RadioButtonListValuePicker
