import moment from 'moment'
import { getValueAsSet } from '../../generic-utilities'
import EValuePickerType from '../types/EValuePickerType'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import IDateRangeDefaultValues from '../types/DateRangeValuePicker/IDateRangeDefaultValues'
import IDateRangeNullable from '../types/DateRangeValuePicker/IDateRangeNullable'
import {
    getOverridingDefaultValuesForDateRange,
    getOverridingDefaultValuesForToggle,
} from './ValuePickerValueOverrides'

const constructDefaultValuesForDateRange = (defaultValues: IDateRangeDefaultValues): IDateRangeNullable => {
    const { StartFromCurrentDay, StartFromCurrentDayUnit, EndFromCurrentDay, EndFromCurrentDayUnit, StartOfUnit } =
        defaultValues

    let start = null

    if (StartOfUnit && StartFromCurrentDay !== null) {
        start = moment()
            .add(StartFromCurrentDay, StartFromCurrentDayUnit || 'year')
            .startOf(StartOfUnit || 'year')
            .startOf('day')
    } else if (StartFromCurrentDay !== null) {
        start = moment()
            .add(StartFromCurrentDay, StartFromCurrentDayUnit || 'year')
            .startOf('day')
    }

    const end = EndFromCurrentDay === null ? null : moment().add(EndFromCurrentDay, EndFromCurrentDayUnit || 'year')

    return {
        start,
        end,
    }
}

export const getValuePickerInitialState = (
    valuePickerConfiguration: TValuePickerConfiguration,
    currentPage = ''
): unknown => {
    const { ValuePickerType, DefaultValues, ValuePickerId } = valuePickerConfiguration

    switch (ValuePickerType) {
        case EValuePickerType.TimeRange: {
            return {
                end: '',
                start: '',
            }
        }
        case EValuePickerType.DateRange: {
            const overridingDefaultValuesForDateRange = getOverridingDefaultValuesForDateRange(
                ValuePickerId,
                currentPage
            )
            return constructDefaultValuesForDateRange(
                overridingDefaultValuesForDateRange ?? (DefaultValues as IDateRangeDefaultValues)
            )
        }
        case EValuePickerType.CheckboxPicker:
        case EValuePickerType.Toggle: {
            const overridingDefaultValuesForToggle = getOverridingDefaultValuesForToggle(ValuePickerId, currentPage)
            return overridingDefaultValuesForToggle || valuePickerConfiguration.DefaultValues || false
        }
        case EValuePickerType.TextSearch: {
            return ''
        }
        case EValuePickerType.DropdownV2: {
            return valuePickerConfiguration.DefaultValues ?? null
        }
        case EValuePickerType.Dropdown: {
            return valuePickerConfiguration.DefaultValues
                ? getValueAsSet(valuePickerConfiguration.DefaultValues)
                : new Set()
        }
        default: {
            return valuePickerConfiguration.DefaultValues ?? null
        }
    }
}
