import * as React from 'react'
import { connect } from 'react-redux'

import { TranslatedButton } from '../../generic-components'
import { BoundThunk } from '../../generic-state'
import { fetchListDataThunk } from '../Thunks/ConfigurableListThunks'

interface IOwnProps {
    listId: string
}

interface IDispatchProps {
    fetchListData: BoundThunk<typeof fetchListDataThunk>
}

interface IProps extends IOwnProps, IDispatchProps {}

const ListFetchButtonUnconnected: React.FunctionComponent<IProps> = ({ listId, fetchListData }) => {
    const handleClick = () => {
        fetchListData(listId, { resetSelected: true, resetOffset: true })
    }

    return (
        <TranslatedButton onClick={handleClick} large>
            configurable-list.FetchDataButton.Label
        </TranslatedButton>
    )
}

const mapDispatchToProps = {
    fetchListData: fetchListDataThunk,
}

export default connect(null, mapDispatchToProps)(ListFetchButtonUnconnected)
