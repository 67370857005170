import { useDispatch, useSelector } from 'react-redux'
import { selectValuePickerConfigurations, selectValuePickersValues, setValuePickersValuesAction } from '../value-picker'
import IBaseValuePickerConfiguration from '../value-picker/types/IBaseValuePickerConfiguration'
import { getValueAsArray } from '../generic-utilities'
import { compact, isEmpty, isEqual, isNil } from 'lodash-es'
import { RootState } from 'typesafe-actions'
import { useMemo } from 'react'
import { selectCurrentPage } from '../navigation/State/NavigationSelectors'

export const useAreFiltersAtDefaultState = (
    filterIds: string[]
): { filtersAtDefaultState: boolean; filters: { id: string; isDefault: boolean; value: unknown }[] } => {
    const valuePickerConfigurations = useSelector((state: RootState) =>
        selectValuePickerConfigurations(state, filterIds)
    ) as IBaseValuePickerConfiguration<unknown>[]

    const valuePickersWithoutDateRange = valuePickerConfigurations.filter(
        ({ ValuePickerType }) => ValuePickerType !== 'DateRange' && ValuePickerType !== 'Hidden'
    )

    const valuePickerValues = useSelector((state: RootState) => selectValuePickersValues(state, filterIds))

    const valuePickersAtDefaultState = useMemo(
        () =>
            valuePickersWithoutDateRange.map(({ ValuePickerId, ValuePickerType, DefaultValues }) => {
                const value: any = valuePickerValues[ValuePickerId]
                const valueAsArray = getValueAsArray(valuePickerValues[ValuePickerId])

                const returnValue = { id: ValuePickerId, value }

                if (typeof value === 'boolean') {
                    return { ...returnValue, isDefault: value === DefaultValues }
                }

                if (
                    ValuePickerType === 'TimeRange' &&
                    (value.start === '' ||
                        value.startTime === null ||
                        value.starTime === '' ||
                        value.end === '' ||
                        value.endTime === null ||
                        value.endTime === '')
                ) {
                    return { ...returnValue, isDefault: true }
                }

                if (isNil(value)) {
                    return { ...returnValue, isDefault: true }
                }

                if (!isNil(DefaultValues)) {
                    return { ...returnValue, isDefault: isEqual(valueAsArray, DefaultValues) }
                }

                const compactValue = Array.isArray(valueAsArray) && compact(valueAsArray)

                return { ...returnValue, isDefault: isEmpty(compactValue) }
            }),
        [valuePickerValues, valuePickersWithoutDateRange]
    )

    const filtersAtDefaultState = useMemo(
        () => valuePickersAtDefaultState.every(({ isDefault }) => isDefault),
        [valuePickersAtDefaultState]
    )

    return {
        filtersAtDefaultState,
        filters: valuePickersAtDefaultState,
    }
}

export const useIsFilterSavingEnabled = (): boolean => {
    const currentPage = useSelector(selectCurrentPage)

    const enabledPages = ['PayrollAdministrationEventsV2', 'PayrollAdministrationTransactions', 'StaffingPage']

    return enabledPages.includes(currentPage)
}

export const useResetValuePickerValues = (filterIds: string[]): (() => void) => {
    const dispatch = useDispatch()

    const valuePickerConfigurations = useSelector((state: RootState) =>
        selectValuePickerConfigurations(state, filterIds)
    ) as IBaseValuePickerConfiguration<unknown>[]

    const valuePickersWithoutDateRange = valuePickerConfigurations.filter(
        ({ ValuePickerType }) => ValuePickerType !== 'DateRange' && ValuePickerType !== 'Hidden'
    )

    return () => {
        const emptyValues = new Map<string, any>()

        const getEmptyValue = (type: string) => {
            switch (type) {
                case 'Toggle':
                    return false
                case 'TextSearch':
                    return ''
                case 'TimeRange':
                    return { startTime: null, endTime: null }
                default:
                    return []
            }
        }

        valuePickersWithoutDateRange.forEach(({ ValuePickerId, ValuePickerType, DefaultValues }) => {
            emptyValues.set(ValuePickerId, DefaultValues || getEmptyValue(ValuePickerType))
        })

        dispatch(setValuePickersValuesAction(emptyValues))
    }
}
