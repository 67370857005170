import styled from '@emotion/styled'
import ReadOnlyText from '../ReadOnlyText'
import Icon from '../Icon/Icon'
import { useAppTheme } from '../../theme/'
import { upperFirst } from 'lodash-es'
import { ComponentExtensionsOptions } from '../../theme/MepcoLightTheme'

type Variant = 'info' | 'error' | 'success' | 'warning'

type Props = {
    header?: string
    body?: string
    variant?: Variant
    className?: string
}

const Container = styled.div<{ variant: Variant }>`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 15px;
    border-left-width: 8px;
    border-left-style: solid;
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};

    background-color: ${({ theme, variant }) => {
        const key = `notification${upperFirst(variant)}` as keyof ComponentExtensionsOptions['background']
        return theme.componentExtensions.background[key]
    }};

    border-left-color: ${({ theme, variant }) => {
        const key = `notification${upperFirst(variant)}` as keyof ComponentExtensionsOptions['border']
        return theme.componentExtensions.border[key]
    }};
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`

const InfoBox = ({ header, body, className, variant = 'info' }: Props): JSX.Element => {
    const { componentExtensions } = useAppTheme()

    const getIcon = () => {
        switch (variant) {
            case 'warning':
                return 'warning_amber_outline'
            case 'error':
                return 'error_outline'
            default:
                return 'info_outline'
        }
    }

    return (
        <Container className={className} variant={variant}>
            <Icon color={componentExtensions.icons.iconNeutral}>{getIcon()}</Icon>

            <TextContainer>
                {header && <ReadOnlyText usage="h5">{header}</ReadOnlyText>}
                {body && <ReadOnlyText usage="bodyS">{body}</ReadOnlyText>}
            </TextContainer>
        </Container>
    )
}

export default InfoBox
