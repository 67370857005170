import * as React from 'react'
import Chip from '../../Chip'
import { Translation } from '../../../localization'
import Selector from '../Dropdown/Selector'

export interface IFilterChipOption {
    Id: Identifier
    Name: string
    Nimi: string
    SideEffect?: { key: string; value: unknown }[]
}

export type Identifier = string | number

export interface IFilterChipProps {
    label: string
    onChange?: (val: Identifier[]) => void
    isMultiselect: boolean
    value: Identifier[]
    options: IFilterChipOption[]
    isLoading?: boolean
    useSearch?: boolean
    useFilter?: boolean
    onSearchTermChange?: (value: string) => void
    searchTermValue?: string
    itemOptionLabelFields?: string[]
    autoFocusFilter?: boolean
}

const FilterChip: React.FunctionComponent<IFilterChipProps> = ({
    isMultiselect,
    label,
    onChange,
    value,
    options,
    useSearch,
    useFilter,
    onSearchTermChange,
    searchTermValue,
    itemOptionLabelFields,
    autoFocusFilter,
    isLoading,
}) => {
    const labelToUse = Translation.has(label) ? Translation.translateKey(label) : label

    return (
        <Selector
            viewComponentRenderer={(onMenuOpen) => (
                <Chip
                    disabled={false}
                    isSelectionIconRendered
                    label={labelToUse}
                    onClick={onMenuOpen}
                    selectionCount={value.length}
                    spaceReservedForSelectionCount={18}
                />
            )}
            options={options}
            isMultiselect={isMultiselect}
            label={labelToUse}
            onChange={onChange}
            value={value}
            useFilter={useFilter}
            useSearch={useSearch}
            onChangeSearchTerm={onSearchTermChange}
            searchTerm={searchTermValue}
            itemOptionLabelFields={itemOptionLabelFields}
            autoFocusFilter={autoFocusFilter}
            isLoading={isLoading}
        />
    )
}

export default FilterChip
