import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { setJSONItemToLocalStorage } from '../../generic-utilities'
import { isEmpty, isNil } from 'lodash-es'
import { Button } from '../Buttons'
import { setCookiesAcceptedAction } from '../../legacy/action-creators'
import ReadOnlyText from '../ReadOnlyText'
import { translate } from '../../localization'
import moment from 'moment'
import { getCookieSettings } from '../../generic-utilities/StorageUtils'
import { Container } from './shared/Container'
import { Banner } from './shared/Banner'
import { TextSection } from './shared/TextSection'
import { ButtonSection } from './shared/ButtonSection'
import { AboutCookiesLink } from './shared/AboutCookiesLink'
import { CategoryDescription } from './shared/CategoryDescription'

const STORAGE_KEY = 'aikaCookieSettings'

const AikaCookieConsentBanner = (): JSX.Element | null => {
    const dispatch = useDispatch()

    const settings = useSelector((state: RootState) => state.asetukset)
    const hasConsented = isEmpty(settings)
        ? getCookieSettings(STORAGE_KEY)?.categories.functional
        : settings.get('cookiesAccepted')

    const [hasMadeAChoice, setHasMadeAChoice] = useState<boolean>(!isNil(hasConsented))

    const handleCookiesAccepted = () => {
        setJSONItemToLocalStorage(STORAGE_KEY, { date: moment(), categories: { functional: true } })
        dispatch(setCookiesAcceptedAction(true))
        setHasMadeAChoice(true)
    }

    if (hasMadeAChoice && !isNil(hasConsented)) {
        return null
    }

    return (
        <Container data-testid="cookie-consent-banner-aika">
            <Banner>
                <TextSection>
                    <ReadOnlyText usage="h1">{translate('cookieBanner.header')}</ReadOnlyText>
                </TextSection>

                <TextSection>
                    <CategoryDescription>
                        <ReadOnlyText usage="bodyM">{translate('cookieBanner.aikaUsageDescription')}</ReadOnlyText>
                        <ReadOnlyText usage="bodyM">
                            <AboutCookiesLink
                                to={translate('tietoa-evasteista-url')}
                                muiLinkProps={{ underline: 'always' }}
                            >
                                {translate('cookieBanner.aboutCookies')}
                            </AboutCookiesLink>
                        </ReadOnlyText>
                    </CategoryDescription>
                </TextSection>
                <ButtonSection justifyContent="end">
                    <Button onClick={handleCookiesAccepted}>{translate('cookieBanner.aikaAcceptAll')}</Button>
                </ButtonSection>
            </Banner>
        </Container>
    )
}

export default memo(AikaCookieConsentBanner)
