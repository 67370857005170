import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { difference, groupBy, isEmpty, mapValues, partition, sortBy } from 'lodash-es'
import { EValuePickerType, selectValuePickerConfigurations, TValuePickerId, ValuePickerRenderer } from '../value-picker'
import { ListFetchButton, selectListViewFilterIds } from '../configurable-list'
import { RootState } from 'typesafe-actions'
import TopRowActions from './TopRowActions'
import ResetFiltersButton from './ResetFiltersButton'
import { Icon } from '../generic-components'
import { selectShowFetchDataButton } from '../configurable-list/State/ConfigurableListSelectors'
import IBaseValuePickerConfiguration from '../value-picker/types/IBaseValuePickerConfiguration'
import ListActionsBar from './ListActionsBar'
import { translate } from '../localization'
import { useAppTheme } from '../theme'
import SaveFiltersButton from './SaveFiltersButton'
import SavedFiltersSelector from './SavedFiltersSelector'
import { useIsFilterSavingEnabled } from './hooks'
import SettingsSelector from './SettingsSelector'
import ITogglePickerConfiguration from '../value-picker/types/ITogglePickerConfiguration'

type ActionBarProps = {
    listId: string
    omitFilterIds?: TValuePickerId[]
    showExcelButton?: boolean
    accumulatedData?: ReactNode
    additionalButtons?: ReactNode
    onModalSubmitCallback?: () => void
}

const Container = styled.section`
    position: relative;
    margin: 0 8px 12px 8px;
    padding: 20px 20px 44px;
    box-shadow: 0 1px 4px 0 #00000040;
    border-radius: ${({ theme }) => theme.tokens.radiusSmall};
    background-color: ${({ theme }) => theme.componentExtensions.background.primary};
`

const ValuePickersContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 12px;
`

const PickerGroup = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 8px;
`

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
`

const ToggleFiltersVisibilityButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    cursor: pointer;
    border-top: 1px solid ${({ theme }) => theme.colors.primaryTeal11};
    border-bottom-left-radius: ${({ theme }) => theme.tokens.radiusSmall};
    border-bottom-right-radius: ${({ theme }) => theme.tokens.radiusSmall};
    color: ${({ theme }) => theme.componentExtensions.buttons.textButtonTertiaryDefault};
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
    background-color: ${({ theme }) => theme.componentExtensions.background.primary};

    &:hover {
        background-color: ${({ theme }) => theme.colors.primaryTeal11};
    }
`

const FilterActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    height: 22px;
`

const valuePickerGroups: Record<string, string[]> = {
    topRow: ['DateRange', 'TextSearch'],
    dropdowns: ['Dropdown'],
    toggles: ['Toggle'],
}

const valuePickerComponentMapping: Record<string, EValuePickerType> = {
    [EValuePickerType.Toggle]: EValuePickerType.ToggleButton,
    [EValuePickerType.TextSearch]: EValuePickerType.TextPicker,
    [EValuePickerType.TimeRange]: EValuePickerType.TimeRangeChip,
}

// these appear as checkboxes inside the gear icon -dropdown
const pickersMovedToSettings = ['IncludeDeletedEvents', 'PublishedTimesDifferFromActual']

const ActionBarV2 = ({
    listId,
    accumulatedData,
    additionalButtons,
    onModalSubmitCallback,
    showExcelButton = false,
    omitFilterIds = [],
}: ActionBarProps): JSX.Element => {
    const { componentExtensions } = useAppTheme()
    const [filtersVisible, setFiltersVisible] = useState(true)

    const listViewFilterIds = useSelector((state: RootState) => selectListViewFilterIds(state, listId))
    const showFetchDataButton = useSelector((state: RootState) => selectShowFetchDataButton(state, listId))

    const filterSavingEnabled = useIsFilterSavingEnabled()

    const filterIds = difference(listViewFilterIds, omitFilterIds)

    const valuePickerConfigurations = useSelector((state: RootState) =>
        selectValuePickerConfigurations(state, filterIds)
    ) as IBaseValuePickerConfiguration<unknown>[]

    const [settingsPickers, remainingPickers] = partition(valuePickerConfigurations, ({ ValuePickerId }) =>
        pickersMovedToSettings.includes(ValuePickerId)
    )

    const configurationGroups = useMemo(() => {
        return mapValues(
            groupBy(remainingPickers, ({ ValuePickerType }) => {
                return Object.keys(valuePickerGroups).find((key) => valuePickerGroups[key].includes(ValuePickerType))
            }),
            (group, key) => {
                return sortBy(group, (picker) => valuePickerGroups[key]?.indexOf(picker.ValuePickerType))
            }
        )
    }, [remainingPickers])

    const extraProps: Record<string, Record<string, unknown>> = useMemo(
        () => ({
            [EValuePickerType.TextSearch]: {
                placeholder: translate('hae'),
                overrideStyle: { width: '240px' },
                label: '',
                endAdorment: (
                    <Icon color={componentExtensions.icons.iconAction} size={24}>
                        search
                    </Icon>
                ),
            },
        }),
        [componentExtensions]
    )

    const fetchContentButton = showFetchDataButton && <ListFetchButton listId={listId} />

    const renderValuePickers = useCallback(
        (valuePickers?: IBaseValuePickerConfiguration<unknown>[]) => {
            if (!valuePickers) {
                return null
            }

            return valuePickers.map(({ ValuePickerId, ValuePickerType, ...rest }) => (
                <ValuePickerRenderer
                    key={ValuePickerId}
                    valuePickerId={ValuePickerId}
                    valuePickerType={valuePickerComponentMapping[ValuePickerType] || ValuePickerType}
                    fallbackComponent="ValuePicker"
                    useCachedValuesForOptions
                    {...extraProps[ValuePickerType]}
                    {...rest}
                />
            ))
        },
        [extraProps]
    )

    return (
        <>
            <Container>
                <ValuePickersContainer>
                    <TopRow>
                        <PickerGroup>
                            {renderValuePickers(configurationGroups.topRow)}

                            {filterSavingEnabled && <SavedFiltersSelector filterIds={filterIds} />}

                            {fetchContentButton}
                        </PickerGroup>

                        <TopRowActions listId={listId} showExcelButton={showExcelButton} />
                    </TopRow>

                    {filtersVisible && (
                        <>
                            <PickerGroup>
                                {renderValuePickers([
                                    ...(configurationGroups.dropdowns || []),
                                    ...(configurationGroups.undefined || []),
                                ])}

                                {!isEmpty(settingsPickers) && (
                                    <SettingsSelector valuePickers={settingsPickers as ITogglePickerConfiguration[]} />
                                )}
                            </PickerGroup>
                            <PickerGroup>{renderValuePickers(configurationGroups.toggles)}</PickerGroup>
                        </>
                    )}
                </ValuePickersContainer>

                {filtersVisible && (
                    <FilterActionsContainer>
                        <ResetFiltersButton filterIds={filterIds} />
                        {filterSavingEnabled && <SaveFiltersButton filterIds={filterIds} />}
                    </FilterActionsContainer>
                )}

                <ToggleFiltersVisibilityButton onClick={() => setFiltersVisible((current) => !current)}>
                    <Icon>{filtersVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                </ToggleFiltersVisibilityButton>

                <ListActionsBar
                    listId={listId}
                    accumulatedData={accumulatedData}
                    additionalButtons={additionalButtons}
                    onModalSubmitCallback={onModalSubmitCallback}
                />
            </Container>
        </>
    )
}

export default ActionBarV2
