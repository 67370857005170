import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom'
import loadable, { LoadableComponent } from '@loadable/component'
import * as React from 'react'
import URL from 'common/URL'
import LoadingIndicatorGlobal from 'components/molecules/LoadingIndicatorGlobal'
import Application, { IRouteApplicationProps } from 'components/main/Application'
import { IPageData } from '../page'
import CookieConsentBanner from '../generic-components/CookieConsentBanner/CookieConsentBanner'

/* eslint-enable */

const createLoadable = (loader: any) =>
    loadable(loader, {
        fallback: <LoadingIndicatorGlobal isLoading />,
    })

const OsiokohtaisetAsetukset = createLoadable(
    () => import(/* webpackChunkName: "OsiokohtaisetAsetukset" */ '../osiokohtaiset-asetukset')
)

const TyovuorolistaPaketti = createLoadable(
    () => import(/* webpackChunkName: "TyovuorolistaPaketti" */ '../tyovuorolistapaketti')
)
const VuorotJaVapaatPage = createLoadable(async () => {
    const { VuorotJaVapaatPage: VuorotJaVapaat } = await import(
        /* webpackChunkName: "VuorotJaVapaatPage" */ '../payroll-management'
    )

    return VuorotJaVapaat
})
const ToteumatPage = createLoadable(async () => {
    const { ToteumatPage: Toteumat } = await import(/* webpackChunkName: "ToteumatPage" */ '../payroll-management')

    return Toteumat
})

const IntermidiatePageForJWTLogin = createLoadable(async () => {
    const { IntermidiatePageForJWTLogin: IntermidiatePageForJWTLoginPage } = await import(
        /* webpackChunkName: "IntermidiatePageForJWTLogin" */ '../authentication'
    )

    return IntermidiatePageForJWTLoginPage
})
const YllapitolistaPage = createLoadable(async () => {
    return await import(
        /* webpackChunkName: "YllapitolistaPage" */ '../yllapitokayttoliittymat/components/YllapitolistaPage'
    )
})

const YllapitoFormPage = createLoadable(async () => {
    return await import(
        /* webpackChunkName: "YllapitoFormPage" */ '../yllapitokayttoliittymat/components/YllapitoFormPage'
    )
})

const YllapitoDualListPage = createLoadable(async () => {
    return await import(
        /* webpackChunkName: "YllapitoDualListPage" */ '../yllapitokayttoliittymat/components/YllapitoDualListPage'
    )
})

const MuutoslokiPage = createLoadable(
    () => import(/* webpackChunkName: "MuutoslokiPage" */ '../muutosloki/Components/MuutoslokiPage')
)

const WorkforceSchedulingPage = createLoadable(async () => {
    return await import(
        /* webpackChunkName: "WorkforceSchedulingPage" */ '../workforce-scheduling/Components/WorkforceSchedulingPage'
    )
})

const WorkforceSchedulingPageV2 = createLoadable(async () => {
    return await import(
        /* webpackChunkName: "WorkforceSchedulingPage" */ '../workforce-scheduling/v2/WorkforceSchedulingPageV2'
    )
})

const LoadableStaffingPage = createLoadable(async () => {
    return await import(/* webpackChunkName: "StaffingPage" */ '../workforce-scheduling/StaffingPage/StaffingPage')
})

const LoadableModulePage = createLoadable(async () => {
    return await import(/* webpackChunkName: "ModulePage" */ '../yllapitokayttoliittymat/components/ModulePage')
})

const LoadablePalkkausPage = createLoadable(async () => {
    return await import(/* webpackChunkName: "PalkkausPage" */ '../yllapitokayttoliittymat/palkkaus/palkkauspage')
})

const DynamicPage = createLoadable(async () => {
    return await import(/* webpackChunkName: "DynamicPage" */ '../dynamic-pages/components/DynamicPage')
})

const LoginPage = createLoadable(async () => {
    return await import(/* webpackChunkName: "LoginPage" */ '../login/Components/LoginPage')
})

const AikaApp = createLoadable(async () => {
    return await import(/* webpackChunkName: "AikaApp" */ '../micro-frontends/Aika')
})

const DownloadAppPage = createLoadable(async () => {
    return await import(/* webpackChunkName: "DownloadAppPage" */ '../login/Components/DownloadAppPage')
})

type TRouteObject = {
    paths: string[]
    page: LoadableComponent<unknown>
    pageId: string | null
    routeApplicationProps?: IRouteApplicationProps
}

const routeObjects: TRouteObject[] = [
    { paths: ['/osiokohtaisetasetukset/:osionId'], page: OsiokohtaisetAsetukset, pageId: 'OsiokohtaisetAsetukset' },
    {
        paths: ['/palkkahallinto/vuorotjavapaat'],
        page: VuorotJaVapaatPage,
        pageId: 'PayrollAdministrationEvents',
    },
    {
        paths: ['/payrolladministration/events'],
        page: VuorotJaVapaatPage,
        pageId: 'PayrollAdministrationEventsV2',
    },
    {
        paths: ['/payrolladministration/transactions', '/palkkahallinto/toteumat', '/palkkahallinto/toteuma'],
        page: ToteumatPage,
        pageId: 'PayrollAdministrationTransactions',
    },
    { paths: ['/tyovuorolistapaketti'], page: TyovuorolistaPaketti, pageId: 'ShiftlistPackages' },
    {
        paths: ['/workforcescheduling', '/tyovuorosuunnittelu/:planningPeriodId?'],
        page: WorkforceSchedulingPage,
        pageId: 'WorkforceScheduling',
    },
    {
        paths: ['/wfsv2'],
        page: WorkforceSchedulingPageV2,
        pageId: 'WorkforceSchedulingV2',
    },
    {
        paths: ['/staffing'],
        page: LoadableStaffingPage,
        pageId: 'StaffingPage',
    },
    {
        paths: ['/wfs/:planningPeriodId'],
        page: WorkforceSchedulingPage,
        pageId: 'WorkforceScheduling',
    },
    {
        paths: ['/IntermidiatePageForJWTLogin/:jwt'],
        page: IntermidiatePageForJWTLogin,
        pageId: null,
        routeApplicationProps: { isPageWithoutUserInitialization: true, isPageRenderedByItself: true },
    },
    { paths: ['/muutosloki'], page: MuutoslokiPage, pageId: 'ChangeLog' },
    { paths: ['/yllapito/lista/:listId'], page: YllapitolistaPage, pageId: null },
    { paths: ['/yllapito/form/:formId'], page: YllapitoFormPage, pageId: null },
    { paths: ['/yllapito/duallist'], page: YllapitoDualListPage, pageId: null },
    { paths: ['/page/:pageId', '/maintenance/:pageId'], page: DynamicPage, pageId: null },
    { paths: ['/modules'], page: LoadableModulePage, pageId: null },
    { paths: ['/palkkauspoc'], page: LoadablePalkkausPage, pageId: null },
    { paths: ['/palkkaus'], page: LoadablePalkkausPage, pageId: null },
    {
        paths: ['/aika'],
        page: AikaApp,
        pageId: null,
        routeApplicationProps: { isPageFullHeight: true },
    },
    {
        paths: ['/download-app'],
        page: DownloadAppPage,
        pageId: null,
        routeApplicationProps: { isPageRenderedByItself: true, isPageWithoutUserInitialization: true },
    },
    {
        paths: ['/'],
        page: LoginPage,
        pageId: null,
        routeApplicationProps: { isPageRenderedByItself: true, isPageWithoutUserInitialization: true },
    },
]

interface IPageComponentProps extends RouteComponentProps {
    pageData: IPageData | null
}

type TPageComponent = React.ComponentType<IPageComponentProps>
interface IRouteWithApplicationProps extends RouteComponentProps {
    Component: TPageComponent
    pageId: TRouteObject['pageId']
    routeApplicationProps: IRouteApplicationProps | undefined
}

const RouteWithApplication = (props: IRouteWithApplicationProps) => {
    const { Component, pageId, routeApplicationProps, ...otherProps } = props
    return (
        <Application pageId={pageId} {...routeApplicationProps} {...otherProps}>
            {(pageData: IPageData | null) => (
                <>
                    <Component pageData={pageData} {...otherProps} />
                    <CookieConsentBanner />
                </>
            )}
        </Application>
    )
}

const Routes: React.FunctionComponent = () => (
    <BrowserRouter basename={URL.clientWithoutBaseURL()}>
        <Switch>
            {routeObjects.map(({ paths, page, pageId, routeApplicationProps }) =>
                paths.map((path) => (
                    <Route
                        key={path}
                        path={path}
                        render={(props) => (
                            <RouteWithApplication
                                Component={page as TPageComponent}
                                pageId={pageId}
                                routeApplicationProps={routeApplicationProps}
                                {...props}
                            />
                        )}
                    />
                ))
            )}
        </Switch>
    </BrowserRouter>
)

export default Routes
