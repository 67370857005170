import { useDispatch, useSelector } from 'react-redux'
import { ListExcelButton, selectDataSourceId } from '../configurable-list'
import { selectListStaticActions } from '../configurable-list/State/ConfigurableListSelectors'
import { openFormViewConfigurableModalThunk } from '../form-view'
import { IListViewConfigurableModalAction } from '../configurable-list/interfaces/TFunctionalityAction'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'
import { orderBy } from 'lodash-es'
import { useCallback } from 'react'
import { Button } from '../generic-components'
import { getTestId } from '../test'

type Props = {
    listId: string
    showExcelButton: boolean
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
`

const TopRowActions = ({ listId, showExcelButton }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const dataSourceId = useSelector((state: RootState) => selectDataSourceId(state, listId))
    const staticActions = useSelector((state: RootState) => selectListStaticActions(state, listId))

    const handleClick = useCallback(
        (modalId: string) => {
            dispatch(openFormViewConfigurableModalThunk(modalId, dataSourceId, []))
        },
        [dataSourceId, dispatch]
    )

    const actions = orderBy(staticActions, 'Order', 'desc').map((action) => {
        const { Display, Functionality, Order } = action
        const { FormId } = Functionality as IListViewConfigurableModalAction

        return (
            <Button
                key={`${FormId}-${Display.ButtonText}`}
                onClick={() => handleClick(FormId)}
                testId={getTestId(['LIST_BULK_ACTION', 'BTN'], FormId)}
                variant={Order === 1 ? 'contained' : 'outlined'}
                large
            >
                {Display.ButtonText}
            </Button>
        )
    })

    return (
        <Container>
            {showExcelButton && <ListExcelButton listId={listId} />}
            {actions}
        </Container>
    )
}

export default TopRowActions
