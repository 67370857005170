import TValuePickerId from '../types/TValuePickerId'

export const isStateOverriddenForValuePicker = (valuePickerIds: TValuePickerId[]): boolean => {
    return ['ShowOnlyCurrentlyValidContracts', 'EmployeeContractDateRange'].every((id) => valuePickerIds.includes(id))
}

export const getOverridingDefaultValuesForToggle = (valuePickerId: string, currentPage: string): boolean => {
    return valuePickerId === 'ShowOnlyCurrentlyValidContracts' && currentPage.includes('EmployeeContracts')
}

export const getOverridingDefaultValuesForDateRange = (
    valuePickerId: string,
    currentPage: string
): Record<string, number | string> | undefined => {
    if (valuePickerId !== 'EmployeeContractDateRange' || !currentPage.includes('Employees')) {
        return
    }

    return {
        StartFromCurrentDay: -1,
        StartFromCurrentDayUnit: 'year',
        EndFromCurrentDay: 1,
        EndFromCurrentDayUnit: 'year',
    }
}
