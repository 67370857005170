import { createRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import ReactDatepicker from 'react-datepicker'
import styled from '@emotion/styled'

import TextInput from 'components/atoms/TextInput'
import AbstractInput from 'components/atoms/AbstractInput'
import DateUtility from 'common/DateUtility'
import Styles from 'constants/Styles'
import { Translation } from '../../../localization'
import ValidationErrorAfter from 'components/molecules/ValidationErrorAfter'
import { browserIsOnMobileDevice } from '../../../browser-utilities'

import LoginUserContext from 'context/LoginUserContext'

const Container = styled.div`
    display: inline-block;
    width: 100%;
    max-width: 141px;
`

const StyledDatePicker = styled(ReactDatepicker)`
    & {
        width: 141px;
    }
`

// The datepicker component passes a ref but since we have a custom component react will complain about
// that (since you can't pass a ref to components without using forwardRef) so we'll use forwardRef to
// avoid that. But since we don't need it (doesn't seem to actually affect anything) we're not passing
// it forward
// eslint-disable-next-line react/display-name, @typescript-eslint/no-unused-vars
const DatePickerTextField = forwardRef(({ placeholder, value, onClick, onChange }, ref) => {
    return <TextInput label={placeholder} onChange={onChange} onClick={onClick} value={value} />
})

/**
 * Datepicker. Komponentti päivämäärän valintaan.
 * Komponentin arvon muuttuessa defaulttina dispatchaa annetun päivän
 * ajankohtana päivän alun (keskiyön). Jos haluat, että ajankohdaksi otetaan
 * päivän viimeinen hetki, anna propsi aikaPaivanLopusta.
 *
 * @note Arvon (value) täytyy olla Date- tai Moment-objekti. Jälkimmäistä suositellaan. Muussa tapauksessa
 *       ReactDatepicker kaatuu.
 *
 * Esimerkki:
 * <Datepicker />
 */
class Datepicker extends AbstractInput {
    static contextType = LoginUserContext
    constructor(props) {
        super(props)

        this._onChanged = this._onChanged.bind(this)
        this._setDatepickerRef = createRef()
    }

    _dispatchChangedValue(action, value) {
        action.dispatch(value)
    }

    _dateFromStartOrEnd(date) {
        return this.props.aikaPaivanLopusta ? DateUtility.endOf(date, 'date') : DateUtility.startOf(date, 'date')
    }

    _onChanged(newValue, event) {
        const { changeAction, onChange } = this.props

        if (onChange) {
            onChange(newValue)
        }
        const action = changeAction
        if (!action) {
            return
        }

        if (!newValue || !DateUtility.isDate(newValue)) {
            this._dispatchChangedValue(action, null)
            return
        }

        const date = this._dateFromStartOrEnd(newValue)
        this._dispatchChangedValue(action, date)

        if (event) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    /*
     * https://github.com/Hacker0x01/react-datepicker/issues/730
     * Jostakin syystä ilman tätä datepicker ja react-select sotkevat toisiansa niin,
     * että* datepickerin auki ollessa jos valitsee react-select-kirjaston dropdownin,
     * dropdown avautuu ja datepicker sulkeutuu hetkellisesti, mutta samantien
     * datepicker avautuu uudestaan ja dropdown sulkeutuu.
     */
    onClickOutside = () => {
        if (this._setDatepickerRef.current) {
            this._setDatepickerRef.current.cancelFocusInput()
        }
    }

    _getErrorMessageIfNeeded() {
        const { noValidation, value } = this.props

        if (noValidation || DateUtility.isDate(value)) {
            return
        }

        if (!value) {
            return 'validation-pvm-tyhja'
        }

        return 'validation-pvm-virheellinen'
    }

    render() {
        const { doNotTranslate, fixedHeight, hintText, inline, minDate, maxDate, value, className, testId } = this.props
        let placeholder = hintText
        let date = value

        if (!doNotTranslate) {
            placeholder = Translation.translateKey(hintText)
        }

        if (!date || !date._isAMomentObject) {
            date = undefined
        }

        const virheellinenPvm = this._getErrorMessageIfNeeded()

        const onClickOutside = browserIsOnMobileDevice() ? undefined : this.onClickOutside

        return (
            <Container className={className} data-testid={testId}>
                <ValidationErrorAfter message={virheellinenPvm}>
                    <StyledDatePicker
                        customInput={<DatePickerTextField />}
                        dateFormat={Styles.date.displayFormat}
                        fixedHeight={fixedHeight}
                        inline={inline}
                        locale={this.context?.Kieli}
                        maxDate={maxDate}
                        minDate={minDate}
                        onChange={this._onChanged}
                        onChangeRaw={this._onChanged}
                        onClickOutside={onClickOutside}
                        placeholderText={placeholder}
                        ref={this._setDatepickerRef}
                        selected={date}
                    />
                </ValidationErrorAfter>
            </Container>
        )
    }
}

Datepicker.defaultProps = {
    aikaPaivanLopusta: false,
    fixedHeight: false,
    hintText: 'valitse-pvm',
    inline: false,
    noValidation: false,
}

Datepicker.displayName = 'Datepicker'

Datepicker.propTypes = {
    aikaPaivanLopusta: PropTypes.bool,
    doNotTranslate: PropTypes.bool,
    fixedHeight: PropTypes.bool,
    hintText: PropTypes.string,
    inline: PropTypes.bool,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    noValidation: PropTypes.bool,
    value: PropTypes.object,
    testId: PropTypes.string,
}

export default Datepicker
