import { connect, useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { RootState } from 'typesafe-actions'

import { containsHiddenRows, getItemIdsForRender } from '../../Utilities/ConfigurableListHelpers'
import {
    dataSourceSetOffsetAction,
    IDataSourceAction,
    selectActionConfigurations,
    selectDataItemIds,
    selectDataSourceDataStatus,
    selectDataSourceOffset,
    selectIsDataSourceDataFetched,
    TDataSourceId,
} from '../../../data-source'
import { IDataSourceItem } from '../../../data-source-types'
import {
    selectDataSourceId,
    selectIsListSelectable,
    selectIsRowSelectable,
    selectListConfigurationForColumns,
    selectListRowActions,
    selectVisibleIds,
} from '../../State/ConfigurableListSelectors'
import IListViewColumnModel from '../../interfaces/IListViewColumnModel'
import ListEndReachedText from './ListEndReachedText'
import { TListIconData } from '../../interfaces/IList'
import IListViewAction from '../../interfaces/IListViewAction'
import { IListViewIconAction } from './../../interfaces/TDisplayAction'
import TFunctionalityAction from '../../interfaces/TFunctionalityAction'
import Rows from './Rows'
import VirtualizedRows from './VirtualizedRows'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import useFetchMoreDataAtScrollToBottom from './../../Hooks/useFetchMoreDataAtScrollToBottom'
import { MAX_LIST_ROWS, MAX_WORKUNITGROUP_ROWS } from './constants/ListConstants'
import { fetchListDataThunk } from '../../Thunks/ConfigurableListThunks'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { createPortal } from 'react-dom'
import { ReadOnlyText } from '../../../generic-components'
import { isEmpty } from 'lodash-es'

interface IOwnProps {
    iconData?: TListIconData
    isLoadingInitialData: boolean
    listId: string
    onRowSelect?: (item?: IDataSourceItem) => void
    isVirtualized: boolean
    shouldAddScrollListener?: boolean
}

interface IStateProps {
    dataItemIds: (string | number)[]
    allItemIds: (string | number)[]
    displayListEndReachedText: boolean
    metadataForColumns: IListViewColumnModel[]
    massSelectable: boolean
    isDataFetched: boolean
    rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]
    rowsAreSelectable: boolean
    actionConfigurations: IDataSourceAction[]
    dataSourceId: TDataSourceId | null
    offset: number
    visibleIds: string[]
}

interface IListContentProps extends IStateProps, IOwnProps {}

const VirtualizedList = ({
    metadataForColumns,
    massSelectable,
    rowActions,
    iconData,
    listId,
    onRowSelect,
    rowsAreSelectable,
    actionConfigurations,
    dataSourceId,
    visibleIds,
    allItemIds,
    isLoadingInitialData,
    displayListEndReachedText,
    offset,
}: IListContentProps) => {
    const dispatch: ThunkDispatch<any, any, Action> = useDispatch()
    const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)

    const offsetValue = useSelector((state: RootState) => selectDataSourceOffset(state, dataSourceId))
    const dataStatus = useSelector((state: RootState) => selectDataSourceDataStatus(state, dataSourceId))

    const allItemsCount = allItemIds?.length ?? 0

    const maxRows = dataSourceId === 'WorkUnitGroup' ? MAX_WORKUNITGROUP_ROWS : MAX_LIST_ROWS

    const hasNoMoreDataToFetch =
        dataSourceId === 'Event' ? dataStatus !== 'LimitReached' : offset >= allItemsCount - maxRows

    const loadMoreData = useCallback(async () => {
        if (hasNoMoreDataToFetch || isLoadingMoreData || dataSourceId === 'WorkUnitGroup') {
            return
        }

        const newOffset = offsetValue + maxRows
        setIsLoadingMoreData(true)

        dispatch(dataSourceSetOffsetAction(dataSourceId, newOffset))
        await dispatch(fetchListDataThunk(listId, { resetSelected: false, showLoadingSpinner: false }))
        setIsLoadingMoreData(false)
    }, [dataSourceId, dispatch, hasNoMoreDataToFetch, isLoadingMoreData, listId, maxRows, offsetValue])

    return (
        <VirtualizedRows
            actionConfigurations={actionConfigurations}
            dataItemIds={visibleIds}
            dataSourceId={dataSourceId}
            iconData={iconData}
            listId={listId}
            massSelectable={massSelectable}
            metadataForColumns={metadataForColumns}
            onRowSelect={onRowSelect}
            rowActions={rowActions}
            rowsAreSelectable={rowsAreSelectable}
            loadMoreData={loadMoreData}
            isLoading={isLoadingInitialData}
            isLoadingMore={isLoadingMoreData}
            displayListEndReachedText={hasNoMoreDataToFetch && displayListEndReachedText}
            itemsCount={allItemsCount}
        />
    )
}

const List = ({
    metadataForColumns,
    massSelectable,
    rowActions,
    iconData,
    listId,
    onRowSelect,
    rowsAreSelectable,
    actionConfigurations,
    dataSourceId,
    visibleIds,
    allItemIds,
    offset,
    isLoadingInitialData,
    displayListEndReachedText,
    shouldAddScrollListener = false,
}: IListContentProps) => {
    const allItemsCount = allItemIds?.length ?? 0

    const hasNoMoreDataToFetch = offset >= allItemsCount - MAX_LIST_ROWS

    const isLoadingMoreData = useFetchMoreDataAtScrollToBottom(
        allItemsCount,
        dataSourceId,
        listId,
        shouldAddScrollListener,
        hasNoMoreDataToFetch
    )

    return (
        <>
            <Rows
                actionConfigurations={actionConfigurations}
                dataItemIds={visibleIds}
                dataSourceId={dataSourceId}
                iconData={iconData}
                listId={listId}
                massSelectable={massSelectable}
                metadataForColumns={metadataForColumns}
                onRowSelect={onRowSelect}
                rowActions={rowActions}
                rowsAreSelectable={rowsAreSelectable}
            />

            <LoadingIndicatorInline isLoading={isLoadingMoreData} paddingLeft="50%" />

            {!isLoadingMoreData && hasNoMoreDataToFetch && (
                <ListEndReachedText
                    allItemsCount={allItemsCount}
                    isLoading={isLoadingInitialData}
                    maxResultsDisplayed={displayListEndReachedText}
                />
            )}
        </>
    )
}

export const ListContentUnconnected = (props: IListContentProps): JSX.Element => {
    const { isVirtualized, allItemIds } = props

    const rowCountContainer = document.getElementById('row-count-container')

    const rowCountPortal =
        rowCountContainer &&
        createPortal(
            !isEmpty(allItemIds) && (
                <ReadOnlyText usage="list_data">Tuloksia yhteensä {allItemIds.length}</ReadOnlyText>
            ),
            rowCountContainer
        )

    return isVirtualized ? (
        <>
            {rowCountPortal}
            <VirtualizedList {...props} />
        </>
    ) : (
        <List {...props} />
    )
}

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => {
    const dataSourceId = selectDataSourceId(state, listId)
    const offset = selectDataSourceOffset(state, dataSourceId)
    const allItemIds = selectDataItemIds(state, dataSourceId)
    const dataItemIds = getItemIdsForRender(
        allItemIds,
        offset,
        dataSourceId === 'WorkUnitGroup' ? MAX_WORKUNITGROUP_ROWS : MAX_LIST_ROWS
    )
    const metadataForColumns = selectListConfigurationForColumns(state, listId)
    const displayEndText = containsHiddenRows(
        allItemIds,
        dataSourceId === 'WorkUnitGroup' ? MAX_WORKUNITGROUP_ROWS : MAX_LIST_ROWS
    )
    const massSelectable = selectIsListSelectable(state, listId)
    const isDataFetched = selectIsDataSourceDataFetched(state, dataSourceId)
    const rowActions = selectListRowActions(state, listId)
    const visibleIds = selectVisibleIds(state, listId, dataSourceId, offset)

    return {
        dataSourceId,
        offset,
        actionConfigurations: selectActionConfigurations(state, dataSourceId),
        allItemIds,
        dataItemIds,
        displayListEndReachedText: displayEndText,
        metadataForColumns,
        massSelectable,
        isDataFetched,
        rowActions,
        rowsAreSelectable: selectIsRowSelectable(state, listId),
        visibleIds,
    }
}

ListContentUnconnected.displayName = 'ListContentUnconnectedForwardRef'

export default connect(mapStateToProps)(ListContentUnconnected)
