import { useEffect } from 'react'
import { FormFieldState } from '../../Types/V2/FormFieldStateEnum'
import { difference, isEmpty } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { usePrevious } from '../../../generic-utilities'
import IFormViewFieldConfigurationV2 from '../../Types/V2/IFormViewFieldConfigurationV2'
import { resetFieldValuesThunk } from '../../../value-picker/Thunks/ValuePickerThunks'

export const useResetHiddenFields = (
    fieldStates: Record<string, FormFieldState>,
    fieldConfig: IFormViewFieldConfigurationV2[]
): void => {
    const dispatch = useDispatch()

    const previousFieldStates: typeof fieldStates | undefined = usePrevious(fieldStates)

    useEffect(() => {
        if (!previousFieldStates) {
            return
        }

        const getHiddenFields = (fields: typeof fieldStates) => {
            return Object.keys(fields).filter((key) => fields[key] === FormFieldState.Hidden)
        }

        const disappearedFields = difference(getHiddenFields(fieldStates), getHiddenFields(previousFieldStates))

        if (isEmpty(disappearedFields)) {
            return
        }

        const disappearedFieldConfigs = fieldConfig.filter(({ Id }) => disappearedFields.includes(Id))

        dispatch(resetFieldValuesThunk(disappearedFieldConfigs))
    }, [dispatch, fieldConfig, fieldStates, previousFieldStates])
}
