import styled from '@emotion/styled'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { setJSONItemToLocalStorage } from '../../generic-utilities'
import { isEmpty, isNil } from 'lodash-es'
import { Button } from '../Buttons'
import { initializeUserpilot } from '../../legacy/action-creators/InitialisingActions/internal/utilility/InitialisingHelper'
import { setCookiesAcceptedAction, userPilotInitializedAction } from '../../legacy/action-creators'
import ReadOnlyText from '../ReadOnlyText'
import { translate } from '../../localization'
import { Toggle } from '../Inputs/Toggle'
import StatusLabel from '../StatusLabel'
import moment from 'moment'
import { getCookieSettings } from '../../generic-utilities/StorageUtils'
import { Container } from './shared/Container'
import { Banner } from './shared/Banner'
import { TextSection } from './shared/TextSection'
import { ButtonSection } from './shared/ButtonSection'
import { AboutCookiesLink } from './shared/AboutCookiesLink'
import { CategoryDescription } from './shared/CategoryDescription'

const STORAGE_KEY = 'cookieSettings'

type CategoryProps = {
    header: string
    description: string
    alwaysActive?: boolean
    onChange?: (accepted: boolean) => void
}

const ConfirmButtons = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-left: auto;
    flex-wrap: wrap-reverse;
`

const CookieCategory = styled.div`
    display: flex;
    gap: 30px;
    align-items: baseline;
    margin-top: 30px;
`

const CookieCategorySettings = ({ header, description, alwaysActive, onChange }: CategoryProps): JSX.Element => {
    const [checked, setChecked] = useState<boolean>(false)

    const handleChange = () => {
        setChecked((current) => !current)
        onChange && onChange(!checked)
    }

    return (
        <CookieCategory data-testid="cookie-category">
            <CategoryDescription>
                <ReadOnlyText usage="h5">{translate(header)}</ReadOnlyText>
                <ReadOnlyText usage="bodyM">{translate(description)}</ReadOnlyText>
            </CategoryDescription>

            {!alwaysActive ? (
                <Toggle label="" checked={checked} onChange={handleChange} testId="functional-cookies-toggle" />
            ) : (
                <StatusLabel text={translate('cookieBanner.alwaysActive')} variant="visibility" />
            )}
        </CookieCategory>
    )
}

const KeikatCookieConsentBanner = (): JSX.Element | null => {
    const dispatch = useDispatch()

    const user = useSelector((state: RootState) => state.loginUser)
    const settings = useSelector((state: RootState) => state.asetukset)

    // use localStorage as a fallback, since settings reducer is not initialized if user is not logged in
    const hasConsented = isEmpty(settings)
        ? getCookieSettings(STORAGE_KEY)?.categories.functional
        : settings.get('cookiesAccepted')

    const [hasMadeAChoice, setHasMadeAChoice] = useState<boolean>(!isNil(hasConsented))

    const [editMode, setEditMode] = useState<boolean>(false)
    const [functionalCookiesAccepted, setFunctionalCookiesAccepted] = useState<boolean>(false)

    const initComplete = settings.get('userPilotInitialized')
    const instructionsEnabled = settings.get('ActiveInstructionsEnabled')

    const initCallback = useCallback(() => {
        if (initComplete || isNil(user) || !instructionsEnabled) {
            return
        }

        initializeUserpilot(user)
        dispatch(userPilotInitializedAction())
    }, [dispatch, initComplete, instructionsEnabled, user])

    useEffect(() => {
        if (!hasMadeAChoice || !hasConsented) {
            return
        }

        initCallback()
    }, [initCallback, hasConsented, hasMadeAChoice, user])

    const handleChoice = (accepted: boolean) => {
        setJSONItemToLocalStorage(STORAGE_KEY, { date: moment(), categories: { functional: accepted } })
        dispatch(setCookiesAcceptedAction(accepted))
        setHasMadeAChoice(true)

        if (accepted) {
            initCallback()
        }

        setFunctionalCookiesAccepted(false)
    }

    // hasConsented may be reverted back to null via Manage cookies -function
    if (hasMadeAChoice && !isNil(hasConsented)) {
        return null
    }

    const buttons = !editMode ? (
        <>
            <Button variant="outlined" onClick={() => setEditMode(true)}>
                {translate('cookieBanner.modify')}
            </Button>

            <ConfirmButtons>
                <Button variant="outlined" onClick={() => handleChoice(false)}>
                    {translate('cookieBanner.onlyNecessary')}
                </Button>

                <Button onClick={() => handleChoice(true)}>{translate('cookieBanner.acceptAll')}</Button>
            </ConfirmButtons>
        </>
    ) : (
        <ConfirmButtons>
            <Button variant="outlined" onClick={() => handleChoice(true)}>
                {translate('cookieBanner.acceptAll')}
            </Button>

            <Button onClick={() => handleChoice(functionalCookiesAccepted)}>
                {translate('cookieBanner.saveSettings')}
            </Button>
        </ConfirmButtons>
    )

    const categories = editMode && (
        <>
            <CookieCategorySettings
                header="cookieBanner.necessaryHeader"
                description="cookieBanner.necessaryDescription"
                alwaysActive
            />

            <CookieCategorySettings
                header="cookieBanner.functionalHeader"
                description="cookieBanner.functionalDescription"
                onChange={setFunctionalCookiesAccepted}
            />
        </>
    )

    return (
        <Container data-testid="cookie-consent-banner">
            <Banner>
                <TextSection>
                    <ReadOnlyText usage="h1">{translate('cookieBanner.header')}</ReadOnlyText>
                </TextSection>

                <TextSection>
                    <ReadOnlyText usage="bodyM">
                        {translate('cookieBanner.usageDescription')}

                        <AboutCookiesLink
                            to={translate('tietoa-evasteista-url')}
                            muiLinkProps={{ underline: 'always' }}
                        >
                            {translate('cookieBanner.aboutCookies')}
                        </AboutCookiesLink>
                    </ReadOnlyText>

                    {categories}
                </TextSection>

                <ButtonSection justifyContent="center">{buttons}</ButtonSection>
            </Banner>
        </Container>
    )
}

export default memo(KeikatCookieConsentBanner)
