/* eslint-disable no-console */
// Side effect imports
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import AdapterMoment from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@emotion/react'
import { Provider as ReduxProvider } from 'react-redux'
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider'
import { Integrations } from '@sentry/tracing'

import { reduxStore } from '../redux'
import Routes from './Routes'
import { ValuePickerComponentRegistryProvider } from '../value-picker'
import { getValuePickerComponents } from '../value-picker-components'
import { getListComponents } from '../configurable-list-components-registry'
import { ConfigurableListComponentRegistryProvider } from '../configurable-list'
import { FormViewComponentRegistryProvider } from '../form-view'
import { getCustomModalComponents, getFormFieldComponents } from '../form-view-components'
import { getJwt, setJwt } from '../authentication'
import * as Sentry from '@sentry/react'
import { AppTheme, GlobalStyles } from '../theme'

// side effect imports
import 'react-datepicker/dist/react-datepicker.min.css'

if (process.env.NODE_ENV !== 'production') {
    // Display Maps and Sets correctly in Redux DevTools (and maybe in other places as well)
    // Taken from https://github.com/zalmoxisus/redux-devtools-extension/issues/124#issuecomment-221972997
    Map.prototype.toJSON = function () {
        const obj = {}
        this.forEach((value, key) => (obj[key] = value))
        return obj
    }
    Set.prototype.toJSON = function () {
        const obj = []
        this.forEach((value) => obj.push(value))
        return obj
    }
}

type Config = {
    SENTRY_DSN: string
    SENTRY_ENVIRONMENT: string
    SENTRY_PROJECT: string
}

const defaultConfig = {
    SENTRY_DSN: '',
    SENTRY_ENVIRONMENT: 'local-dev',
    SENTRY_PROJECT: '',
}

const config: Config = localStorage?.MEPCO_KEIKAT_FE_CONFIG
    ? JSON.parse(localStorage?.MEPCO_KEIKAT_FE_CONFIG)
    : defaultConfig

if (process.env.INITIALIZE_SENTRY || config.SENTRY_ENVIRONMENT !== 'local-dev') {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: config.SENTRY_ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    })
    if (config.SENTRY_ENVIRONMENT && config.SENTRY_ENVIRONMENT !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Sentry initialized with environment:', config.SENTRY_ENVIRONMENT)
    }
}

if (config.SENTRY_PROJECT) {
    Sentry.setTag('Product', config.SENTRY_PROJECT === 'planier-frontend' ? 'Keikat' : 'Aika')
}

const listComponents = getListComponents()
const valuePickerComponents = getValuePickerComponents()
const formFieldComponents = getFormFieldComponents()
const customModalComponents = getCustomModalComponents()

const App = () => {
    useEffect(() => {
        const token = getJwt()

        if (token && token !== '') {
            setJwt(token)
        }
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={AppTheme}>
                <ThemeProvider theme={AppTheme}>
                    <ReduxProvider store={reduxStore}>
                        <ConfigurableListComponentRegistryProvider components={listComponents}>
                            <ValuePickerComponentRegistryProvider components={valuePickerComponents}>
                                <FormViewComponentRegistryProvider
                                    customModalComponents={customModalComponents}
                                    fieldComponents={formFieldComponents}
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <GlobalStyles />
                                        <Routes />
                                    </LocalizationProvider>
                                </FormViewComponentRegistryProvider>
                            </ValuePickerComponentRegistryProvider>
                        </ConfigurableListComponentRegistryProvider>
                    </ReduxProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    )
}

const container = document.getElementById('Planier')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
