import { fetchDynamicValuePickerConfigurations, getStaticValuePickerConfigurations } from '../WebApi/ValuePickerWebApi'
import { initializeValuePickersAction, setValuePickerValueAction } from '../State/ValuePickerActions'
import {
    selectChangedDependentValuePickerConfigurations,
    selectNonExistentValuePickerIds,
    selectValuePickerConfiguration,
    selectValuePickerValue,
} from '../State/ValuePickerSelectors'
import TValuePickerId from '../types/TValuePickerId'
import { getValuePickerInitialState } from '../Utilities/GetValuePickerInitialState'
import { IThunkBaseAction } from '../../generic-state'
import IInitialValuePickersValues from '../types/IInitialValuePickersValues'
import IDynamicValuePickerConfiguration from '../types/IDynamicValuePickerConfiguration'
import EValuePickerType from '../types/EValuePickerType'
import { getValuePickerValueInCorrectFormat, SAVED_PICKERS_PREFIX } from '../Utilities/ValuePickerValueUtilities'
import { getLogger } from '../../log'
import { displaySuccessToaster } from '../../notifications'
import {
    fetchStoredOptionsDataThunk,
    IFetchStoredOptionsDataThunkOptions,
    IStoredOptionsOptionIdentifierObject,
} from '../../stored-options'
import { CancelToken } from 'axios'
import IDropdownValuePickerConfiguration from '../../value-picker/types/IDropdownV3ValuePickerConfiguration'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import { isEmpty, isNil } from 'lodash-es'
import { setJSONItemToLocalStorage } from '../../generic-utilities/StorageUtils'
import { translate } from '../../localization'
import IFormViewFieldConfigurationV2 from '../../form-view/Types/V2/IFormViewFieldConfigurationV2'
import { selectCurrentPage } from '../../navigation/State/NavigationSelectors'

const Log = getLogger('value-picker.ValuePickerThunks')

export const fetchValuePickerConfigurationsThunk =
    (
        valuePickerIds: TValuePickerId[],
        initialValuePickersValues: IInitialValuePickersValues | null = null,
        tempIsFormViewPicker?: boolean
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()
        const currentPage = selectCurrentPage(state)
        const nonExistingValuePickerIds = selectNonExistentValuePickerIds(state, valuePickerIds)

        if (nonExistingValuePickerIds.length === 0) {
            return
        }

        const staticValuePickerConfigurations = getStaticValuePickerConfigurations(nonExistingValuePickerIds)

        const nonExistingDynamicConfigurations = nonExistingValuePickerIds.filter(
            (x) => !staticValuePickerConfigurations.find((y) => y.ValuePickerId === x)
        )

        let dynamicValuePickerConfigurations: TValuePickerConfiguration[] = []

        if (nonExistingDynamicConfigurations.length !== 0) {
            dynamicValuePickerConfigurations = await fetchDynamicValuePickerConfigurations()
        }

        const valuePickerConfigurations = [...dynamicValuePickerConfigurations, ...staticValuePickerConfigurations]

        const initialStates = valuePickerConfigurations.map((configuration) => {
            //TEMP HACK TO AVOID ALL UNNCESSARY DATAFORMAT RELATED TO OLD VALUEPICKER DROPDOWN
            configuration.ValuePickerType =
                tempIsFormViewPicker && configuration.ValuePickerType === EValuePickerType.Dropdown
                    ? EValuePickerType.DropdownV2
                    : configuration.ValuePickerType

            const { ValuePickerId, ValuePickerType } = configuration

            const determineInitialValue = () => {
                if (initialValuePickersValues?.has(ValuePickerId)) {
                    return getValuePickerValueInCorrectFormat(
                        ValuePickerType,
                        initialValuePickersValues.get(ValuePickerId)
                    )
                }

                const initialValueFromState = state.valuePickerInitialValues[ValuePickerId]

                if (!isNil(initialValueFromState)) {
                    return initialValueFromState
                }

                return getValuePickerInitialState(configuration, currentPage)
            }

            return {
                configuration,
                value: determineInitialValue(),
                isInitialized: false,
            }
        })

        dispatch(initializeValuePickersAction({ page: state.navigationItems.currentPage, valuePickers: initialStates }))
    }

export const registerDynamicValuePickerThunk =
    (
        valuePickerConfiguration: Pick<IDynamicValuePickerConfiguration, 'ValuePickerId' | 'DataRequestParameterName'>,
        value: unknown = null
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const { ValuePickerId } = valuePickerConfiguration

        if (selectValuePickerConfiguration(state, ValuePickerId)) {
            Log.debug('Already initialized value picker with id $0, not initializing again', ValuePickerId)
            return
        }

        dispatch(
            initializeValuePickersAction({
                page: state.navigationItems.currentPage,
                valuePickers: [
                    {
                        configuration: {
                            ...valuePickerConfiguration,
                            ValuePickerType: EValuePickerType.DynamicValuePicker,
                            DependsOn: [],
                            DefaultValues: null,
                        },
                        value,
                        isInitialized: false,
                    },
                ],
            })
        )
    }

export const saveValuePickersValuesToLocalStorageThunk =
    (page: string, valuePickerIds: string[]): IThunkBaseAction =>
    async (dispatch, getState) => {
        const values = valuePickerIds.reduce(
            (obj, valuePickerId) => ({ ...obj, [valuePickerId]: selectValuePickerValue(getState(), valuePickerId) }),
            {}
        )

        setJSONItemToLocalStorage(`${SAVED_PICKERS_PREFIX}-${page}`, values)

        const message = translate('filtersSavedAsDefault')
        dispatch(displaySuccessToaster(message))

        return
    }

export const fetchValuePickerDataThunk =
    <TOption extends IStoredOptionsOptionIdentifierObject>(
        valuePickerId: string,
        thunkOptions?: IFetchStoredOptionsDataThunkOptions,
        dynamicParameters?: Record<string, unknown>,
        dataFetchCancelToken?: CancelToken
    ): IThunkBaseAction<TOption[] | null> =>
    async (dispatch, getState) => {
        const valuePickerConfiguration = selectValuePickerConfiguration(getState(), valuePickerId)

        if (!valuePickerConfiguration || !valuePickerConfiguration.hasOwnProperty('IdentifierUrl')) {
            return null
        }

        const {
            DependsOn: dependsOn,
            IdentifierUrl: optionsEndPointUrl,
            Label: label,
            Limit: limit,
            ExtraRows: extraRows,
            DataRequestParameterName: itemIdField,
        } = valuePickerConfiguration as IDropdownValuePickerConfiguration

        const dependsOnToUse = dependsOn.map(
            ({
                AdditionalParams: additionalParams,
                FilterType: filterType,
                ValuePickerId: componentId,
                ParameterName: parameterName,
            }) => ({
                additionalParams,
                filterType,
                componentId,
                parameterName,
            })
        )
        const queryConfiguration = {
            dependsOn: dependsOnToUse,
            optionsEndPointUrl,
            label,
            limit,
            extraRows,
        }

        const getStoredComponentValue = (valuePickerIdToQueryFor: string) =>
            selectValuePickerValue(getState(), valuePickerIdToQueryFor)

        return dispatch(
            fetchStoredOptionsDataThunk<TOption>(
                valuePickerId,
                queryConfiguration,
                getStoredComponentValue,
                { ...thunkOptions, itemIdField },
                dynamicParameters,
                dataFetchCancelToken
            )
        )
    }

export const fetchDataForDependentValuePickerThunk =
    (changedValuePickerId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const dependentValuePickers = selectChangedDependentValuePickerConfigurations(getState(), changedValuePickerId)

        if (isEmpty(dependentValuePickers)) {
            return
        }

        dependentValuePickers.forEach(({ ValuePickerId }) =>
            dispatch(fetchValuePickerDataThunk(ValuePickerId, { useCachedValues: false }))
        )
    }

export const resetFieldValuesThunk =
    (fields: IFormViewFieldConfigurationV2[]): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        fields.forEach((field) => {
            const configuration = selectValuePickerConfiguration(state, field.ValuePickerId)
            const value = configuration && getValuePickerInitialState(configuration)

            if (typeof value === 'boolean') {
                return
            }

            dispatch(setValuePickerValueAction(value, field.ValuePickerId, field.Id))
        })
    }
