import styled from '@emotion/styled'

export const Banner = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 24px;
    bottom: 24px;
    margin-left: 24px;
    max-width: 730px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.componentExtensions.global.bgPrimary};
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
`
