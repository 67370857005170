import styled from '@emotion/styled'
import { Icon, Tooltip } from '../../../generic-components'
import * as React from 'react'

type Props = {
    tooltip: string
    isMultiline?: boolean
    isDropdown?: boolean
}

const InfoIconContainer = styled.div<{ isMultiline?: boolean; isDropdown?: boolean }>`
    position: relative;
    display: flex;
    margin-left: 8px;
    top: ${({ isMultiline }) => (isMultiline ? -90 : 0)}px;

    ${({ isDropdown }) =>
        isDropdown &&
        `
            position: absolute;
            right: -40px;
            top: 25px;
        `}
`

const InfoIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 32px;
`

const ValuePickerComponentTooltip = ({ tooltip, isMultiline, isDropdown }: Props): JSX.Element => {
    return (
        <InfoIconContainer isMultiline={isMultiline} isDropdown={isDropdown}>
            <Tooltip title={tooltip} placement="bottom-start">
                <InfoIconWrapper className="info-icon">
                    <Icon size={24} colorTheme="primary">
                        info_outlined
                    </Icon>
                </InfoIconWrapper>
            </Tooltip>
        </InfoIconContainer>
    )
}

export default ValuePickerComponentTooltip
