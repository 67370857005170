import * as React from 'react'
import { Grid } from '@material-ui/core'
import moment, { Moment } from 'moment'
import styled from '@emotion/styled'

import TranslatedTypography from '../../TranslatedTypography'
import TimeLength from '../TimeLength'
import Checkbox from '../Checkbox'
import TimeInputV2, { ITimeInputProps } from '../TimeInput/TimeInputV2'
import DateUtility from 'common/DateUtility'
import { Translation } from '../../../localization'
import { getTestId } from '../../../test'

const StyledCheckbox = styled(Checkbox)`
    width: auto;
    padding: 0;
`

const GridWithMargin = styled(Grid)`
    margin-right: 45px;
    margin-bottom: 10px;
`

const Over24HoursContainer = styled.div``

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const StyledContainerGrid = styled(Grid)`
    align-items: baseline;
`

export interface IValue {
    startTime: Moment | string | null
    endTime: Moment | string | null
    timeLength: number
    isOver24Hours: boolean
}

export const isTimeRangeValue = (value: unknown): boolean => {
    if (typeof value === 'object' && value !== null && value !== undefined) {
        return 'startTime' in value || 'endTime' in value
    }

    return false
}

export interface ITimeRangeValidationError {
    startTimeError?: string | null
    endTimeError?: string | null
}

export interface ITimeRangeInputProps {
    value: IValue
    onBlur?: ({ field }: { field: string }) => void
    handleChange: (value: IValue) => void
    disabled: boolean
    required?: boolean
    validationErrorEndTime?: string | null
    validationErrorStartTime?: string | null
    valuePickerId?: string
    over24hHidden?: boolean
    durationHidden?: boolean
    renderInput?: (start: ITimeInputProps, end: ITimeInputProps) => JSX.Element
}

const DATETIME_TO_COMPARE_TIMES = moment('01-01-2000', 'MM-DD-YYYY')

//Time is handled as a Moment with date part 01-01-2000 and all seconds and milliseconds are zeroed.
//This works fine in all other cases than with days when we change the timezone (+2 -> +3 -> +2)
const adjustedTime = (startDate: Moment | string | undefined, val: Moment | string, addOneDay: boolean) => {
    const valueAsMoment = moment(val).seconds(0).milliseconds(0)

    const newValue = DATETIME_TO_COMPARE_TIMES.clone()
        .add(addOneDay ? 1 : 0, 'days')
        .hours(valueAsMoment.hour())
        .minutes(valueAsMoment.minute())

    if (startDate) {
        const startDateAsMoment = moment(startDate)

        const diffInDays = startDateAsMoment.diff(valueAsMoment, 'days')

        const adjustedValue = newValue.clone().add(diffInDays, 'days')

        return adjustedValue
    } else {
        return newValue
    }
}

const TimeRangeInput: React.FunctionComponent<ITimeRangeInputProps> = ({
    handleChange,
    value,
    value: { timeLength, isOver24Hours },
    disabled = false,
    required,
    validationErrorEndTime = null,
    validationErrorStartTime = null,
    over24hHidden = false,
    durationHidden = false,
    valuePickerId,
    renderInput,
}) => {
    const startTime = value && value.startTime ? adjustedTime(value.startTime, value.startTime, false) : null
    const endTime = value && value.endTime ? adjustedTime(value.startTime ?? undefined, value.endTime, false) : null

    const minuteDifference = (newStartTime: Moment, newEndTime: Moment) => {
        const minuteDiff = moment(newEndTime).diff(newStartTime, 'minutes')

        return minuteDiff > 0 ? minuteDiff : minuteDiff + 24 * 60
    }

    const calculateTimeLength = (_startTime: Moment | null, _endTime: Moment | null, _isOver24Hours: boolean) => {
        return _startTime && _endTime ? minuteDifference(_startTime, _endTime) + (_isOver24Hours ? 24 * 60 : 0) : 0
    }

    const handleAlkuaikaSelection = (_newStartTime: Moment | null) => {
        const newStartTime = _newStartTime ? adjustedTime(undefined, _newStartTime, false) : null

        const newLength = calculateTimeLength(newStartTime, endTime, isOver24Hours)

        handleChange({
            isOver24Hours: isOver24Hours,
            startTime: newStartTime,
            timeLength: newLength,
            endTime: endTime,
        })
    }

    const handleEndTimeSelect = (_newEndTime: Moment | null) => {
        let newEndTime = _newEndTime ? adjustedTime(undefined, _newEndTime, isOver24Hours) : null

        const newLength = calculateTimeLength(startTime, newEndTime, isOver24Hours)

        //If endtime is before the start, move the end time to the following day
        const minuteDiff = newEndTime && startTime ? moment(newEndTime).diff(startTime, 'minutes') : 0

        if (minuteDiff < 0 && newEndTime) {
            newEndTime = newEndTime.add(1, 'days')
        }

        handleChange({
            isOver24Hours: isOver24Hours,
            startTime: startTime,
            timeLength: newLength,
            endTime: newEndTime,
        })
    }

    const handleTimeLengthChange = (newTimeLength: number) => {
        const newEndTime = startTime
            ? moment(startTime as Moment)
                  .seconds(0)
                  .add(newTimeLength, 'minutes')
            : null

        const timeLengthOver24Hours = newTimeLength / 60 > 24
        const newIsOver24Hours = timeLengthOver24Hours

        handleChange({
            startTime,
            endTime: newEndTime,
            timeLength: newTimeLength,
            isOver24Hours: newIsOver24Hours,
        })
    }

    const handleOver24HoursChange = (newIsOver24Hours: boolean) => {
        if (startTime && endTime) {
            //if both times are defined, we add / subtract the length by one day a
            handleTimeLengthChange(newIsOver24Hours ? timeLength + 24 * 60 : timeLength - 24 * 60)
        } else {
            //else we just pass the parameter change
            handleChange({
                startTime,
                endTime,
                timeLength,
                isOver24Hours: newIsOver24Hours,
            })
        }
    }

    const timeLengthDisabled = !DateUtility.isMomentAsTime(startTime) && !DateUtility.isMomentAsTime(endTime)
    const over24HoursDisabled = !DateUtility.isMomentAsTime(startTime)
    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    if (renderInput) {
        return renderInput(
            {
                onChange: handleAlkuaikaSelection,
                label: Translation.translateKey('alkuaika'),
                disabled: disabled,
                required: required,
                errors: validationErrorStartTime ?? undefined,
                value: startTime as Moment,
            },
            {
                onChange: handleEndTimeSelect,
                label: Translation.translateKey('loppuaika'),
                disabled: disabled,
                required: required,
                errors: validationErrorStartTime ?? undefined,
                value: endTime as Moment,
            }
        )
    }

    return (
        <Container data-testid={testId}>
            <StyledContainerGrid container>
                <GridWithMargin item>
                    <TimeInputV2
                        onChange={handleAlkuaikaSelection}
                        label={Translation.translateKey('alkuaika')}
                        disabled={disabled}
                        required={required}
                        errors={validationErrorStartTime ?? undefined}
                        value={startTime as Moment}
                    />
                </GridWithMargin>
                <GridWithMargin item>
                    <TimeInputV2
                        onChange={handleEndTimeSelect}
                        label={Translation.translateKey('loppuaika')}
                        errors={validationErrorEndTime ?? undefined}
                        disabled={disabled}
                        required={required}
                        value={endTime as Moment}
                    />
                    {!over24hHidden && (
                        <Over24HoursContainer>
                            <StyledCheckbox
                                disabled={disabled || over24HoursDisabled}
                                onClick={handleOver24HoursChange}
                                value={isOver24Hours}
                            />
                            <TranslatedTypography variant="caption">Tapahtuma.TapahtumaOver24h</TranslatedTypography>
                        </Over24HoursContainer>
                    )}
                </GridWithMargin>
                {!durationHidden && (
                    <Grid item>
                        <TimeLength
                            disabled={disabled || timeLengthDisabled}
                            handleChange={handleTimeLengthChange}
                            valueInMinutes={timeLength}
                        />
                    </Grid>
                )}
            </StyledContainerGrid>
        </Container>
    )
}

export default TimeRangeInput
