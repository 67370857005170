import { RootState } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { selectIsBarona } from './AsetusSelectors'

export const selectIsExternalUser = (state: RootState): boolean => {
    return state.loginUser?.IsExternalUser ?? false
}

export const selectIsBaronaExternalUser = createSelector(
    selectIsExternalUser,
    selectIsBarona,
    (isExternal, isBarona) => isExternal && isBarona
)
