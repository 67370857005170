import styled from '@emotion/styled'
import { IDropdownListOption } from './Dropdown'
import { dark_100 } from 'constants/Styles'
import Checkbox from '../Checkbox'
import TextInput from '../TextInput/TextInputV2'
import Icon from '../../Icon'
import TranslatedTypography from '../../TranslatedTypography'
import { difference, isEmpty } from 'lodash-es'
import Progress from 'components/molecules/Progress'
import { ReadOnlyText } from '../../index'
import { useCallback } from 'react'
import { translate } from '../../../localization'
import { useAppTheme } from '../../../theme'

interface ISelectorOptionsProps {
    isMultiselect?: boolean
    onClick: (option: IDropdownListOption | []) => void
    onMultipleOptionsSelected: (selectedOptions: number[]) => void
    options: IDropdownListOption[]
    onClose: () => void
    selectedOptions: number[]
    onClearSelection: () => void
    useFilter: boolean
    onChangeFilter: (filterText: string) => void
    filterText: string
    showClearSelection?: boolean
    itemOptionLabelFields?: string[]
    autoFocusFilter?: boolean
    isLoading?: boolean
}

interface IOptionProps {
    selected: boolean
}

interface IOptionsFilterProps {
    onChange?: (filterText: string) => void
    value: string
    isLoading?: boolean
    autoFocus?: boolean
}

interface IClearSelectionButtonProps {
    isMultiselect?: boolean
    onClick: () => void
    selectedOptions: number[]
}

const Container = styled.div`
    border: 0;
    box-sizing: border-box;
    max-height: 328px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    gap: 8px;
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
`

const Option = styled.div<IOptionProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 4px;

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
    }
`

const Text = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    color: ${dark_100};
`

const OptionsFilterContainer = styled.div`
    padding-left: 6px;
    padding-right: 0;
    position: relative;
`

const ClearSelectionButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    margin-bottom: 4px;
`

const ClearSelectionButtonText = styled.button`
    color: ${({ theme }) => theme.componentExtensions.buttons.textButtonTertiaryDefault};
    font-family: 'Inter';
    font-size: 14px;
    line-height: 22px;
    height: 14px;
    cursor: pointer;
`

const SelectAllContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const NoResultsText = styled(TranslatedTypography)`
    margin-top: 10px;
    margin-bottom: 10px;
`

const LoadingIndicator = styled(Progress)`
    position: absolute;
    right: 40px;
    top: 25%;
`

const SelectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const OptionsFilter = ({ onChange, value, isLoading, autoFocus = false }: IOptionsFilterProps) => {
    const { componentExtensions } = useAppTheme()

    return (
        <OptionsFilterContainer>
            <TextInput
                overrideStyle={{ width: '256px' }}
                value={value}
                onChange={onChange}
                endAdorment={
                    <Icon color={componentExtensions.icons.iconAction} size={24}>
                        search
                    </Icon>
                }
                autoFocus={autoFocus}
                placeholder={translate('hae')}
            />
            {isLoading && <LoadingIndicator size={20} />}
        </OptionsFilterContainer>
    )
}

const ClearSelectionButton = ({ onClick, selectedOptions }: IClearSelectionButtonProps) => {
    const buttonText = !isEmpty(selectedOptions) ? (
        <ClearSelectionButtonText onClick={onClick}>{translate('tyhjenna-valinnat')}</ClearSelectionButtonText>
    ) : (
        <ClearSelectionButtonText />
    )

    return <ClearSelectionButtonContainer>{buttonText}</ClearSelectionButtonContainer>
}

const SelectorOptions = ({
    isMultiselect,
    options,
    onClick,
    onMultipleOptionsSelected,
    selectedOptions,
    useFilter,
    onChangeFilter,
    onClearSelection,
    filterText,
    itemOptionLabelFields,
    autoFocusFilter,
    isLoading,
    showClearSelection = true,
}: ISelectorOptionsProps): JSX.Element => {
    const allOptionsSelected = selectedOptions.length === options.length

    const selections = options.filter(({ Id }) => selectedOptions.some((selectedOption) => selectedOption === Id))

    const handleOnClick = useCallback(
        (option: IDropdownListOption) => {
            onClick(option)
        },
        [onClick]
    )

    const handleSelectAll = () => {
        const newOptions = !allOptionsSelected
            ? (options.map((option: IDropdownListOption) => option.Id) as number[])
            : []

        onMultipleOptionsSelected(newOptions)
    }

    const getOptionsBlock = useCallback(
        (optionsList: typeof options) =>
            optionsList.map((option) => {
                const selected = selectedOptions.some((selectedOption) => selectedOption === option.Id)

                const optionText = () => {
                    if (!itemOptionLabelFields) {
                        return option.Name
                    }

                    return itemOptionLabelFields
                        .map((labelField) => (option as Record<string, any>)[labelField])
                        .join(' ')
                }

                return (
                    <Option
                        key={option.Id}
                        onClick={() => handleOnClick(option)}
                        selected={selected}
                        data-testid={`SELECTOR_OPTION_${option.Id}`}
                    >
                        {isMultiselect ? <Checkbox onClick={() => handleOnClick(option)} value={selected} /> : null}
                        <ReadOnlyText usage="bodyS">{optionText()}</ReadOnlyText>
                    </Option>
                )
            }),
        [handleOnClick, isMultiselect, itemOptionLabelFields, selectedOptions]
    )

    const selectAllOption = isMultiselect && !isEmpty(options) && (
        <SelectAllContainer data-testid="SELECTOR_CHECKBOX_SELECT_ALL">
            <Option selected={allOptionsSelected} onClick={handleSelectAll}>
                <Checkbox onClick={handleSelectAll} value={allOptionsSelected} />
                <Text>
                    <TranslatedTypography>valitse-kaikki</TranslatedTypography>
                </Text>
            </Option>
        </SelectAllContainer>
    )

    return (
        <Container>
            {showClearSelection && (
                <ClearSelectionButton onClick={onClearSelection} selectedOptions={selectedOptions} />
            )}
            {useFilter && (
                <OptionsFilter
                    onChange={onChangeFilter}
                    value={filterText}
                    autoFocus={autoFocusFilter}
                    isLoading={isLoading}
                />
            )}
            {isEmpty(options) && <NoResultsText>no-results-found</NoResultsText>}
            {selectAllOption}

            {!isEmpty(selections) && <SelectionsContainer>{getOptionsBlock(selections)}</SelectionsContainer>}

            {getOptionsBlock(difference(options, selections))}
        </Container>
    )
}

export default SelectorOptions
