import { Button } from '../generic-components'
import { translate } from '../localization'
import { useAreFiltersAtDefaultState, useResetValuePickerValues } from './hooks'

type Props = {
    filterIds: string[]
}

const ResetFiltersButton = ({ filterIds }: Props): JSX.Element | null => {
    const { filtersAtDefaultState } = useAreFiltersAtDefaultState(filterIds)
    const resetValuePickerValues = useResetValuePickerValues(filterIds)

    if (filtersAtDefaultState) {
        return null
    }

    return (
        <Button startIcon="clear" variant="text" onClick={resetValuePickerValues}>
            {translate('tyhjenna-valinnat')}
        </Button>
    )
}

export default ResetFiltersButton
