import { isAikaDomain } from '../../generic-utilities/Domain'
import AikaCookieConsentBanner from './AikaCookieConsentBanner'
import KeikatCookieConsentBanner from './KeikatCookieConsentBanner'

const CookieConsentBanner = (): JSX.Element => {
    const isAika = isAikaDomain()
    const Component = isAika ? AikaCookieConsentBanner : KeikatCookieConsentBanner

    return <Component />
}

export default CookieConsentBanner
