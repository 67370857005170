import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { fetchValuePickerConfigurationsThunk } from '../../Thunks/ValuePickerThunks'
import { selectValuePickerConfiguration } from '../../State/ValuePickerSelectors'
import ValuePickerComponent, { ValuePickerConfiguration } from './ValuePickerComponent'
import IBaseValuePickerConfiguration from '../../types/IBaseValuePickerConfiguration'

type Props = {
    valuePickerId: string
    [key: string]: any
    valuePickerComponentProperties?: any
    note?: string
    isRequired?: boolean
    isReadOnly?: boolean
    isInitialValueSet?: boolean
    onValueChange?: (value: any) => void
    label?: string
    contextId?: string
    context?: 'form' | 'list' | 'unknown'
    value?: any
    error?: string
    fallbackComponent?: 'ValuePickerStateless' | 'ValuePicker'
}

const ValuePickerRenderer = ({
    valuePickerId,
    label,
    contextId,
    context,
    note,
    value,
    error,
    isRequired,
    isReadOnly,
    isInitialValueSet,
    valuePickerType,
    onValueChange,
    valuePickerComponentProperties = {},
    fallbackComponent = 'ValuePickerStateless',
    ...rest
}: Props): JSX.Element | null => {
    const dispatch = useDispatch()

    const valuePickerConfiguration = useSelector((state: RootState) =>
        selectValuePickerConfiguration(state, valuePickerId)
    ) as IBaseValuePickerConfiguration<unknown>

    useEffect(() => {
        dispatch(fetchValuePickerConfigurationsThunk([valuePickerId]))
    }, [dispatch, valuePickerId])

    if (!valuePickerConfiguration) {
        return null
    }

    // combine passed properties with properties from picker's own configuration
    const componentProps = {
        ...valuePickerComponentProperties,
        ...valuePickerConfiguration?.valuePickerComponentProperties,
    }

    const configuration = {
        ...valuePickerConfiguration,
        ValuePickerType: valuePickerType || valuePickerConfiguration?.ValuePickerType,
    }

    return (
        <ValuePickerComponent
            {...configuration}
            isRequired={isRequired}
            required={isRequired}
            notes={note}
            configuration={configuration as ValuePickerConfiguration}
            valuePickerComponentProperties={componentProps}
            onChange={onValueChange}
            error={error}
            value={value}
            valuePickerId={valuePickerId}
            label={label}
            isInitialValueSet={isInitialValueSet}
            contextId={contextId}
            context={context}
            fallbackComponent={fallbackComponent}
            isReadOnly={isReadOnly}
            {...rest}
        />
    )
}

export default ValuePickerRenderer
