import moment, { Moment } from 'moment'
import { RootState } from 'typesafe-actions'
import DateConstants from 'constants/DateConstants'
import EValuePickerType from '../types/EValuePickerType'
import IDateRangeNullable from '../types/DateRangeValuePicker/IDateRangeNullable'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import { selectValuePickerConfigurations, selectValuePickerValue } from '../State/ValuePickerSelectors'
import { set } from 'lodash-es'

export const selectValuePickerValuesForQueryFromValuePickerIds = (
    state: RootState,
    valuePickerIds: string[]
): ReturnType<typeof selectValuePickerValuesForQuery> => {
    const valuePickerConfigurations = selectValuePickerConfigurations(state, valuePickerIds)
    const parametersFromValuePickers = selectValuePickerValuesForQuery(state, valuePickerConfigurations)

    return parametersFromValuePickers
}

export const selectValuePickerValuesForQuery = (
    state: RootState,
    valuePickerConfigurations: TValuePickerConfiguration[]
): { [key: string]: unknown } => {
    const urlParameters: { [key: string]: unknown } = {}

    valuePickerConfigurations.forEach(({ DataRequestParameterName, ValuePickerId, ValuePickerType }) => {
        switch (ValuePickerType) {
            case EValuePickerType.ConfigurableList:
            case EValuePickerType.TagList:
            case EValuePickerType.Dropdown:
            case EValuePickerType.DropdownV3:
            case EValuePickerType.TimeRange:
            case EValuePickerType.Toggle:
            case EValuePickerType.DynamicValuePicker: {
                let value = selectValuePickerValue(state, ValuePickerId)

                if (value instanceof Set) {
                    value = [...value]
                }

                if (Array.isArray(value) && value.length > 0) {
                    setDataRequestParameter(urlParameters, DataRequestParameterName, value)
                } else if (!Array.isArray(value)) {
                    setDataRequestParameter(urlParameters, DataRequestParameterName, value)
                }

                break
            }
            case EValuePickerType.TextSearch: {
                const value = selectValuePickerValue(state, ValuePickerId)

                if (value) {
                    setDataRequestParameter(urlParameters, DataRequestParameterName, value)
                }
                break
            }
            case EValuePickerType.DateRangeV2: {
                const valuePickerValue = selectValuePickerValue(state, ValuePickerId) as {
                    startDate: Moment | null
                    endDate: Moment | null
                }

                const startDate = valuePickerValue?.startDate
                const endDate = valuePickerValue?.endDate

                const value = {
                    Alku: moment.isMoment(startDate)
                        ? startDate.startOf('day').format(DateConstants.QUERY_DATE_FORMAT)
                        : null,
                    Loppu: moment.isMoment(endDate)
                        ? moment(endDate).add(1, 'day').startOf('day').format(DateConstants.QUERY_DATE_FORMAT)
                        : null,
                }

                setDataRequestParameter(urlParameters, DataRequestParameterName, value)

                break
            }
            case EValuePickerType.DateRange: {
                const { start, end } = selectValuePickerValue(state, ValuePickerId) as IDateRangeNullable

                const value = {
                    Alku: moment.isMoment(start) ? start.format(DateConstants.QUERY_DATE_FORMAT) : null,
                    Loppu: moment.isMoment(end)
                        ? moment(end).add(1, 'day').startOf('day').format(DateConstants.QUERY_DATE_FORMAT)
                        : null,
                }

                setDataRequestParameter(urlParameters, DataRequestParameterName, value)

                break
            }
            default: {
                const value = selectValuePickerValue(state, ValuePickerId)
                setDataRequestParameter(urlParameters, DataRequestParameterName, value)
            }
        }
    })

    return urlParameters
}

const setDataRequestParameter = (
    urlParameters: { [key: string]: unknown },
    DataRequestParameterName: any,
    value: any
) => {
    if (DataRequestParameterName) {
        set(urlParameters, DataRequestParameterName, value)
    }
}
