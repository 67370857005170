import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { selectDataSourceId } from '../../State/ConfigurableListSelectors'
import { RootState } from 'typesafe-actions'
import {
    dataSourceSetFetchFiltersParametersAction,
    selectIsDataSourceDataFetched,
    selectIsDataSourceInitialized,
} from '../../../data-source'
import { ChangeEvent, useEffect, useState } from 'react'
import { fetchListDataThunk } from '../../Thunks/ConfigurableListThunks'
import { TOTEUMAT_LIST_ID } from '../../../payroll-management/Modules/VuorotJaVapaatPage/Constants/VuorotJaVapaatConstants'
import { selectIsExternalUser } from '../../../legacy/reducers/UserSelectors'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { translate } from '../../../localization'
import { ReadOnlyText } from '../../../generic-components'
import { useAppTheme } from '../../../theme'

type Props = {
    listId: string
}

const tabTranslations: Record<string, string> = {
    Hyvaksymatta: 'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.hyvaksymatta',
    Hyvaksytyt: 'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.hyvaksytyt',
    Kasitellyt: 'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.kasitellyt',
    Kaikki: 'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.kaikki',
    Siirtamattomat: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirtamattomat',
    SiirtoonHyvaksytyt: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirtoonHyvaksytyt',
    Siirretty: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirretty',
}

const hyvaksyntaTabs = {
    Hyvaksymatta: 'Hyvaksymatta',
    Hyvaksytyt: 'Hyvaksytyt',
    Kasitellyt: 'Kasitellyt',
    Kaikki: 'Kaikki',
}

const toteumaTabs = {
    Siirtamattomat: 'Siirtamattomat',
    SiirtoonHyvaksytyt: 'SiirtoonHyvaksytyt',
    Siirretty: 'Siirretty',
    Kaikki: 'Kaikki',
}

const tabValues = {
    ...hyvaksyntaTabs,
    ...toteumaTabs,
} as const

const statusValues = {
    Hyvaksymatta: 1,
    Hyvaksytyt: 2,
    Kasitellyt: 3,
    SiirtoaOdottava: 1,
    SiirtoonValittu: 2,
    Siirretty: 3,
} as const

type StatusTab = keyof typeof tabValues

const Container = styled.div`
    display: flex;
    position: relative;
    top: 2px;
    width: fit-content;
    margin-left: 8px;
    padding-bottom: 2px;
    box-shadow: 0 4px 4px 0 #00000040;
    background-color: ${({ theme }) => theme.componentExtensions.background.primary};
    border-top-left-radius: ${({ theme }) => theme.tokens.radiusDefault};
    border-top-right-radius: ${({ theme }) => theme.tokens.radiusDefault};
`

const getDefaultTab = (listId: string, isExternalUser?: boolean) => {
    if (listId === TOTEUMAT_LIST_ID) {
        return !isExternalUser ? tabValues.Siirtamattomat : tabValues.Kaikki
    }

    return tabValues.Hyvaksymatta
}

const getStatusForTab = (newSelectedTab: StatusTab, listId: string) => {
    switch (newSelectedTab) {
        case tabValues.Hyvaksymatta:
            return [statusValues.Hyvaksymatta]
        case tabValues.Hyvaksytyt:
            return [statusValues.Hyvaksytyt]
        case tabValues.Kasitellyt:
            return [statusValues.Kasitellyt]
        case tabValues.Siirtamattomat:
            return [statusValues.SiirtoaOdottava]
        case tabValues.SiirtoonHyvaksytyt:
            return [statusValues.SiirtoonValittu]
        case tabValues.Siirretty:
            return [statusValues.Siirretty]
        case tabValues.Kaikki:
        default:
            return listId === TOTEUMAT_LIST_ID
                ? [statusValues.SiirtoaOdottava, statusValues.SiirtoonValittu, statusValues.Siirretty]
                : [statusValues.Hyvaksymatta, statusValues.Hyvaksytyt, statusValues.Kasitellyt]
    }
}

const StatusTabs = ({ listId }: Props): JSX.Element | null => {
    const { componentExtensions } = useAppTheme()

    const dispatch = useDispatch()

    const isExternalUser = useSelector(selectIsExternalUser)

    const [selectedTab, setSelectedTab] = useState(getDefaultTab(listId, isExternalUser))
    const [fetchDataOnTabChange, setFetchDataOnTabChange] = useState(false)

    const dataSourceId = useSelector((state: RootState) => selectDataSourceId(state, listId))

    const isDataSourceInitialised = useSelector((state: RootState) =>
        selectIsDataSourceInitialized(state, dataSourceId)
    )

    const isDataFetched = useSelector((state: RootState) => selectIsDataSourceDataFetched(state, dataSourceId))

    const isToteumaList = listId === TOTEUMAT_LIST_ID

    useEffect(() => {
        if (!isDataSourceInitialised || !dataSourceId) {
            return
        }

        const status = getStatusForTab(selectedTab as StatusTab, listId)

        dispatch(
            dataSourceSetFetchFiltersParametersAction(
                dataSourceId,
                isToteumaList ? { Toteumastatukset: status } : { hyvaksyntaStatukset: status }
            )
        )

        if (isDataFetched && fetchDataOnTabChange) {
            dispatch(fetchListDataThunk(listId, { resetSelected: true, resetOffset: true }))
        }
    }, [
        dataSourceId,
        isDataSourceInitialised,
        selectedTab,
        isDataFetched,
        listId,
        fetchDataOnTabChange,
        dispatch,
        isToteumaList,
    ])

    const handleChange = (event: ChangeEvent<unknown>, newSelectedTab: StatusTab) => {
        setSelectedTab(newSelectedTab)

        if (isDataFetched) {
            setFetchDataOnTabChange(true)
        }
    }

    if (isToteumaList && isExternalUser) {
        return null
    }

    const tabs = isToteumaList ? toteumaTabs : hyvaksyntaTabs

    return (
        <Container>
            <Tabs indicatorColor="primary" onChange={handleChange} textColor="primary" value={selectedTab}>
                {Object.keys(tabs).map((tab) => {
                    const isSelected = selectedTab === tab
                    const color = isSelected ? componentExtensions.text.action : componentExtensions.text.disabledDarker

                    return (
                        <Tab
                            key={tab}
                            value={tab}
                            label={
                                <ReadOnlyText usage="h6" rawColor={color} fontSize="14px">
                                    {translate(tabTranslations[tab])}
                                </ReadOnlyText>
                            }
                        />
                    )
                })}
            </Tabs>
        </Container>
    )
}

export default StatusTabs
