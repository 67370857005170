export default {
    cookieBanner: {
        aboutCookies: {
            fi: 'Tietoa evästeistä',
            sv: '',
            en: 'Info about cookies',
            ru: '',
            sk: '',
            pl: '',
        },
        acceptAll: {
            fi: 'Hyväksy kaikki',
            sv: '',
            en: 'Accept all',
            ru: '',
            sk: '',
            pl: '',
        },
        onlyNecessary: {
            fi: 'Vain välttämättömät',
            sv: '',
            en: 'Necessary only',
            ru: '',
            sk: '',
            pl: '',
        },
        modify: {
            fi: 'Muokkaa',
            sv: '',
            en: 'Modify',
            ru: '',
            sk: '',
            pl: '',
        },
        manageCookies: {
            fi: 'Hallinnoi evästeitä',
            sv: '',
            en: 'Manage cookies',
            ru: '',
            sk: '',
            pl: '',
        },
        header: {
            fi: 'Näin käytämme evästeitä',
            sv: '',
            en: 'How we use cookies',
            ru: '',
            sk: '',
            pl: '',
        },
        alwaysActive: {
            fi: 'Aina aktiivinen',
            sv: '',
            en: 'Always active',
            ru: '',
            sk: '',
            pl: '',
        },
        saveSettings: {
            fi: 'Tallenna asetukset',
            sv: '',
            en: 'Save settings',
            ru: '',
            sk: '',
            pl: '',
        },
        necessaryHeader: {
            fi: 'Välttämättömät',
            sv: '',
            en: 'Necessary',
            ru: '',
            sk: '',
            pl: '',
        },
        necessaryDescription: {
            fi: 'Nämä evästeet ovat välttämättömiä palvelun toiminnan kannalta, eikä niitä voi ottaa poistaa käytöstä. Niitä käytetään yleensä silloin kun saavut palveluun tai käytät sen ominaisuuksia.',
            sv: '',
            en: 'These cookies are required for the service to work, and they cannot be disabled. They are usually used when you access the site or use its features.',
            ru: '',
            sk: '',
            pl: '',
        },
        functionalHeader: {
            fi: 'Toiminnalliset',
            sv: '',
            en: 'Functional',
            ru: '',
            sk: '',
            pl: '',
        },
        functionalDescription: {
            fi: 'Toiminnallisten evästeiden avulla voimme tarjota parempia palveluita, kuten vinkkejä palvelun käyttöön. Kerättyä tietoa voidaan käyttää myös palvelun tai sen toimivuuden kehittämiseen. Voimme itse asettaa tällaisia evästeitä tai ne voi asettaa kolmas osapuoli. Jos et hyväksy tällaisia evästeitä, jotkin palvelun ominaisuudet eivät välttämättä toimi oikein.',
            sv: '',
            en: 'Functional cookies enable us to provide better services, such as to show tips for using the service. Data collected through functional cookies may also be used to improve the service or its functionality. Such cookies may be set by us or a third party. If you do not accept such cookies, some features of the service may not work properly.',
            ru: '',
            sk: '',
            pl: '',
        },
        usageDescription: {
            fi: 'Käytämme palvelussamme sen toiminnan kannalta välttämättömiä evästeitä sekä mm. sivuston käyttökokemusta parantavia evästeitä. Hyväksymällä evästeet saat palvelun kaikki toiminnallisuudet käyttöösi.',
            sv: '',
            en: 'Our service uses cookies that are necessary for its functioning, as well as cookies for e.g. improving the user experience of the site. If you accept cookies, all the functions in the service are available.',
            ru: '',
            sk: '',
            pl: '',
        },
        aikaUsageDescription: {
            fi: 'Käytämme palvelussamme sen toiminnan kannalta välttämättömiä evästeitä.',
            sv: '',
            en: 'Our service uses cookies that are strictly necessary for its function.',
            ru: '',
            sk: '',
            pl: '',
        },
        aikaAcceptAll: {
            fi: 'Ok',
            sv: '',
            en: 'Ok',
            ru: '',
            sk: '',
            pl: '',
        },
    },
}
