import { Checkbox, ChipWithPopover, Icon } from '../generic-components'
import { useAppTheme } from '../theme'
import { useCallback } from 'react'
import ITogglePickerConfiguration from '../value-picker/types/ITogglePickerConfiguration'
import { selectValuePickersValues, setValuePickerValueAction } from '../value-picker'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'

type Props = {
    valuePickers: ITogglePickerConfiguration[]
}

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const SettingsSelector = ({ valuePickers }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const { componentExtensions } = useAppTheme()

    const values = useSelector((state: RootState) =>
        selectValuePickersValues(
            state,
            valuePickers.map(({ ValuePickerId }) => ValuePickerId)
        )
    )

    const handleChange = useCallback(
        (value: boolean, valuePicker: ITogglePickerConfiguration) => {
            const sideEffects = !value ? valuePicker.TrueValueSideEffects : valuePicker.FalseValueSideEffects

            dispatch(setValuePickerValueAction(!value, valuePicker.ValuePickerId, undefined, sideEffects))
        },
        [dispatch]
    )

    return (
        <ChipWithPopover
            label={<Icon color={componentExtensions.icons.iconAction}>settings</Icon>}
            spaceReservedForSelectionCount={18}
            selectionCount={Object.values(values).filter((value) => value === true).length}
            isSelectionIconRendered
        >
            <CheckboxContainer>
                {valuePickers.map((valuePicker) => {
                    const value = Boolean(values[valuePicker.ValuePickerId])

                    return (
                        <Checkbox
                            key={valuePicker.ValuePickerId}
                            id={valuePicker.ValuePickerId}
                            label={valuePicker.Label}
                            value={value}
                            onClick={() => handleChange(value, valuePicker)}
                        />
                    )
                })}
            </CheckboxContainer>
        </ChipWithPopover>
    )
}

export default SettingsSelector
