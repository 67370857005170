import * as React from 'react'
import { ComponentProps, useEffect, useRef } from 'react'
import styled from '@emotion/styled'

import ConfigurableList from '../../ConfigurableList'
import TValuePickerId from '../../../value-picker/types/TValuePickerId' // TODO Cyclic dependency
import { IDataSourceItem } from '../../../data-source-types'
import { TListIconData } from '../../interfaces/IList'
import ListAggregatedData from './../ListAggregatedData'
import { IInitialValuePickersValues } from '../../../value-picker'
import { ISummaryLabel, SummaryLabels } from '../../../generic-components/SummaryLabel/SummaryLabels'
import useResizeObserver from '../../../generic-utilities/Hooks/useResizeObserver'
import { useDispatch } from 'react-redux'
import { resetAllSelectedListItemsAction, setFiltersHeightAction } from '../../State/ConfigurableListActions'
import { TABS_HEIGHT } from '../../Constants/ListConstants'
import StatusTabs from './StatusTabs'
import LegacyActionBar from '../../../action-bar/LegacyActionBar'
import FeatureFlag from '../../../generic-utilities/FeatureFlag/FeatureFlag'
import ActionBarV2 from '../../../action-bar/ActionBarV2'
import { isStatusTabsVisible } from '../../../action-bar/utils'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const RowCountContainer = styled.div`
    margin: 0 8px 8px;
`

type TConfigurableListProps = ComponentProps<typeof ConfigurableList>

export interface IOwnProps extends Pick<TConfigurableListProps, 'shouldEmptyDataWhenUnmounting'> {
    accumulatedData?: React.ReactNode
    additionalActions?: TListIconData
    additionalButtons?: React.ReactNode
    additionalDataSourceDataRequestFiltersParameters?: Record<string, unknown> | null
    listId: string
    omitFilterIds?: TValuePickerId[]
    onRowSelect?: (item: IDataSourceItem) => void
    renderAggregatedData?: boolean
    showExcelButton?: boolean
    listIsVirtualized?: boolean
    shouldAddScrollListener?: boolean
    initialValuePickersValues?: IInitialValuePickersValues | null
    hideFilterHeader?: boolean
    resetValuePickerValues?: boolean
    valuePickersVisibilityTogglable?: boolean
    isValuePickerGroupFullWidth?: boolean
}

interface IConfigurableListPageTemplateProps extends IOwnProps {
    summaryLabels?: ISummaryLabel[]
}

const ConfigurableListTemplate: React.FunctionComponent<IConfigurableListPageTemplateProps> = ({
    accumulatedData,
    additionalActions,
    additionalButtons,
    additionalDataSourceDataRequestFiltersParameters = null,
    listId,
    onRowSelect,
    renderAggregatedData = false,
    omitFilterIds = [],
    showExcelButton = false,
    listIsVirtualized = false,
    shouldAddScrollListener = false,
    initialValuePickersValues = null,
    shouldEmptyDataWhenUnmounting,
    hideFilterHeader = false,
    resetValuePickerValues = false,
    valuePickersVisibilityTogglable = false,
    isValuePickerGroupFullWidth = false,
    summaryLabels = [],
}) => {
    const dispatch = useDispatch()
    const filtersRef = useRef<HTMLDivElement>(null)

    const showStatusTabs = isStatusTabsVisible(listId)

    const { height } = useResizeObserver(filtersRef)

    useEffect(() => {
        dispatch(setFiltersHeightAction(height + TABS_HEIGHT, listId))
    }, [dispatch, height, listId])

    const getModalSubmitCallback = () => {
        switch (listId) {
            case 'VuorotJaVapaatList':
                return () => dispatch(resetAllSelectedListItemsAction('VuorotJaVapaatList'))

            default:
                return undefined
        }
    }

    return (
        <Container>
            {summaryLabels.length > 0 && <SummaryLabels summaryLabels={summaryLabels} />}

            {!hideFilterHeader && (
                <div ref={filtersRef}>
                    <FeatureFlag
                        flag="ACTION_BAR_RENEWAL"
                        fallback={
                            <LegacyActionBar
                                accumulatedData={accumulatedData}
                                additionalButtons={additionalButtons}
                                isValuePickerGroupFullWidth={isValuePickerGroupFullWidth}
                                listId={listId}
                                omitFilterIds={omitFilterIds}
                                showExcelButton={showExcelButton}
                                valuePickersVisibilityTogglable={valuePickersVisibilityTogglable}
                                onModalSubmitCallback={getModalSubmitCallback()}
                                renderButtonsAndFiltersWithinPaper
                            />
                        }
                    >
                        <ActionBarV2
                            listId={listId}
                            omitFilterIds={omitFilterIds}
                            showExcelButton={showExcelButton}
                            accumulatedData={accumulatedData}
                            additionalButtons={additionalButtons}
                            onModalSubmitCallback={getModalSubmitCallback()}
                        />
                    </FeatureFlag>
                </div>
            )}

            <TabsContainer>
                {showStatusTabs && <StatusTabs listId={listId} />}
                <RowCountContainer id="row-count-container" />
            </TabsContainer>

            <ConfigurableList
                additionalDataSourceDataRequestFiltersParameters={additionalDataSourceDataRequestFiltersParameters}
                iconData={additionalActions}
                initialValuePickersValues={initialValuePickersValues}
                isVirtualized={listIsVirtualized}
                listId={listId}
                onRowSelect={onRowSelect}
                shouldAddScrollListener={shouldAddScrollListener}
                shouldEmptyDataWhenUnmounting={shouldEmptyDataWhenUnmounting}
                resetValuePickerValues={resetValuePickerValues}
            />
            {renderAggregatedData && <ListAggregatedData listId={listId} />}
        </Container>
    )
}

export default ConfigurableListTemplate
