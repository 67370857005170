import Label from './Label'
import styled from '@emotion/styled'
import Selector, { ISizes, SelectorComponent } from './Selector'

export interface IDropdownListOption {
    Id: Identifier
    Name: string
}

export type Identifier = string | number

export interface IDropdownProps extends ISizes {
    label: string
    disabled?: boolean
    required?: boolean
    onChange?: (val: number[]) => void
    onSearchTermChange?: (value: string) => void
    isMultiselect?: boolean
    value: number[]
    options: IDropdownListOption[]
    isLoading?: boolean
    useFilter?: boolean
    placeholder?: string
    showClearSelection?: boolean
    autoFocusFilter?: boolean
    itemOptionLabelFields?: string[]
    isReadOnly?: boolean
    fullWidth?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Dropdown = (props: IDropdownProps): JSX.Element => {
    const { label, disabled, required, itemOptionLabelFields = ['Name'], onChange, onSearchTermChange } = props

    return (
        <Container data-testid="dropdown">
            <Label disabled={disabled} text={label} required={required}>
                <Selector
                    {...props}
                    errorText=""
                    viewComponentRenderer={(onMenuOpen) => (
                        <SelectorComponent {...props} onClick={onMenuOpen} onChange={onChange} />
                    )}
                    itemOptionLabelFields={itemOptionLabelFields}
                    onChangeSearchTerm={onSearchTermChange}
                />
            </Label>
        </Container>
    )
}

export default Dropdown
