import styled from '@emotion/styled'
import { useAreFiltersAtDefaultState, useResetValuePickerValues } from './hooks'
import { getJSONItemFromLocalStorage, setJSONItemToLocalStorage, usePrevious } from '../generic-utilities'
import { SAVED_PICKERS_PREFIX } from '../value-picker/Utilities/ValuePickerValueUtilities'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentPage } from '../navigation/State/NavigationSelectors'
import Label from '../generic-components/Inputs/Dropdown/Label'
import { SelectorComponent } from '../generic-components/Inputs/Dropdown/Selector'
import { PopoverWithComponent, ReadOnlyText } from '../generic-components'
import { isEmpty, isEqual, isNil, omit, sortBy } from 'lodash-es'
import { translate } from '../localization'
import { loadSavedValuePickersValues } from '../value-picker/State/ValuePickerActions'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import IconButton from '../generic-components/Buttons/IconButton/IconButton'
import { useAppTheme } from '../theme'

type Props = {
    filterIds: string[]
}

const StyledPopover = styled(PopoverWithComponent)`
    .MuiPopover-paper {
        padding: 0;
    }
`

const SelectorContainer = styled.div`
    width: 240px;
`

const List = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 250px;
    list-style: none;
    padding: 0;
`

const ListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;

    .delete-settings-button {
        visibility: hidden;
    }

    :hover {
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};

        .delete-settings-button {
            visibility: visible;
        }
    }
`

const ListEmptyText = styled(ReadOnlyText)`
    margin-left: 16px;
`

const SavedFiltersSelector = ({ filterIds }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const { colors } = useAppTheme()

    const currentPage = useSelector(selectCurrentPage)

    const settingsKey = `${SAVED_PICKERS_PREFIX}-${currentPage}`

    const [selectedSettings, setSelectedSettings] = useState<string | undefined>()
    const [storedFilters, setStoredFilters] = useState(
        getJSONItemFromLocalStorage<Record<string, Record<string, unknown>>>(settingsKey)
    )

    const options = sortBy(Object.keys(storedFilters || {}))

    const didSelectSavedFilters = useRef(false)

    const resetValuePickerValues = useResetValuePickerValues(filterIds)

    const { filters } = useAreFiltersAtDefaultState(filterIds)
    const previousFilters = usePrevious(filters)

    const getComparableFilters = useCallback((filterList?: typeof filters) => {
        if (isNil(filterList)) {
            return []
        }

        return filterList.map(({ id, value }) => ({ id, value }))
    }, [])

    useEffect(() => {
        if (didSelectSavedFilters.current) {
            didSelectSavedFilters.current = false
            return
        }

        if (
            !isNil(selectedSettings) &&
            !isEqual(getComparableFilters(filters), getComparableFilters(previousFilters))
        ) {
            setSelectedSettings(undefined)
        }
    }, [filters, getComparableFilters, previousFilters, selectedSettings])

    const updateStoredFilters = () => {
        setStoredFilters(getJSONItemFromLocalStorage<Record<string, Record<string, unknown>>>(settingsKey))
    }

    const handleMenuOpen = (onMenuOpen: () => void) => {
        updateStoredFilters()
        onMenuOpen()
    }

    const handleFiltersSelection = (settingsName: string, handleClose: () => void) => {
        didSelectSavedFilters.current = true

        handleClose()
        resetValuePickerValues()
        dispatch(loadSavedValuePickersValues({ page: currentPage, settingsName }))
        setSelectedSettings(settingsName)
    }

    const handleDeleteSettings = (event: MouseEvent, settingsName: string) => {
        event.stopPropagation()

        setJSONItemToLocalStorage(settingsKey, omit(storedFilters, settingsName))
        updateStoredFilters()
    }

    return (
        <Label text={translate('savedFilters')}>
            <StyledPopover
                anchorOriginHorizontal={0}
                openPopoverRenderer={(onMenuOpen) => (
                    <SelectorContainer>
                        <SelectorComponent
                            options={options.map((key) => ({ Id: options.indexOf(key), Name: key }))}
                            value={!isNil(selectedSettings) ? [options.indexOf(selectedSettings)] : []}
                            onClick={(event: MouseEvent) => handleMenuOpen(() => onMenuOpen(event))}
                            fullWidth
                        />
                    </SelectorContainer>
                )}
            >
                {(handleClose) => (
                    <List>
                        {isEmpty(options) && <ListEmptyText usage="bodyS">{translate('noSavedFilters')}</ListEmptyText>}

                        {options.map((key) => (
                            <ListItem key={key} onClick={() => handleFiltersSelection(key, handleClose)}>
                                <ReadOnlyText usage="bodyS">{key}</ReadOnlyText>
                                <IconButton
                                    icon="clear"
                                    size="small"
                                    color={colors.neutralsGrey100}
                                    className="delete-settings-button"
                                    onClick={(e) => handleDeleteSettings(e, key)}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </StyledPopover>
        </Label>
    )
}

export default SavedFiltersSelector
