import { connect, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { IDataSourceAggregatedDataRow } from '../../data-source-types'
import { TDataSourceId } from '../Types/IDataSource'
import { selectAccumulatedData, selectAggregatedData } from '../State/DataSourceSelectors'
import { selectIsBaronaExternalUser } from '../../legacy/reducers/UserSelectors'

interface IStateProps {
    aggregatedData: IDataSourceAggregatedDataRow[]
    oldAccumulatedData: IDataSourceAggregatedDataRow[]
}

interface IOwnProps {
    dataSourceId: TDataSourceId | null
    renderData: (aggregatedData: IDataSourceAggregatedDataRow[]) => JSX.Element
}

export interface IAggregatedDataProps extends IStateProps, IOwnProps {}

export const AggregatedDataUnconnected = ({
    aggregatedData,
    oldAccumulatedData,
    renderData,
}: IAggregatedDataProps): JSX.Element | null => {
    const isBaronaExternalUser = useSelector(selectIsBaronaExternalUser)

    if (aggregatedData.length === 0 && oldAccumulatedData.length === 0) {
        return null
    }

    let dataToRender = aggregatedData.length > 0 ? aggregatedData : oldAccumulatedData

    if (isBaronaExternalUser) {
        dataToRender = dataToRender.filter(({ Label }) => !isBaronaExternalUser || Label !== 'Rikotut työaikasäännöt')
    }

    return renderData(dataToRender)
}

const mapStateToProps = (state: RootState, { dataSourceId }: IOwnProps): IStateProps => ({
    aggregatedData: selectAggregatedData(state, dataSourceId),
    oldAccumulatedData: selectAccumulatedData(state, dataSourceId),
})

export default connect(mapStateToProps)(AggregatedDataUnconnected)
