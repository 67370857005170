import { ComponentProps, ReactNode } from 'react'
import { connect, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'

import EmployeeLink from 'components/molecules/Tyontekija'
import GroupNode from './GroupNode'
import { TDataSourceItemId } from '../../data-source-types'
import { BoundThunk } from '../../generic-state'
import { openListModalThunk } from '../../configurable-list'
import { selectStoredOptionsOptionByPropertyMatchingValue } from '../../stored-options'
import { getValueAsArray } from '../../generic-utilities'

import { IDateRange, selectValuePickerValue } from '../../value-picker'
import {
    CALENDAR_DATEPICKER_ID,
    PLANNING_PERIODS_VALUE_PICKER_ID,
    SUITABLE_EVENTS_LIST_ID,
    TARVENAKYMA_CALENDAR_ID,
    WORKUNIT_VALUEPICKER_ID,
} from '../../workforce-scheduling/Constants/WorkforceSchedulingConstants'
import ISuunnittelujakso from '../../workforce-scheduling/Types/ISuunnittelujakso'
import { addEventEmployeeThunk } from '../../workforce-scheduling/State/Thunks/WorkforceSchedulingThunks'
import { getIsLegacyEnvironment } from '../../legacy-environment-adapter'
import Styles from 'constants/Styles'
import { selectIsBaronaExternalUser } from '../../legacy/reducers/UserSelectors'

type TGroupNodeProps = ComponentProps<typeof GroupNode>

export interface IEmployeeGroupNodeProps extends IStateProps, IDispatchProps, IEmployeeGroupNodeOwnProps {}

export interface IEmployeeGroupNodeOwnProps extends Pick<TGroupNodeProps, 'groupedByData' | 'kpiData'> {
    name: string
    value: number
    kpiData: string[] | null
    employeeId: number
    infoText: string
}

interface IStateProps {
    suunnittelujakso: ISuunnittelujakso | null
    daterange: IDateRange | null
    workunits: Set<number> | null
}

interface IDispatchProps {
    addEventEmployee: BoundThunk<typeof addEventEmployeeThunk>
    openListModal: BoundThunk<typeof openListModalThunk>
}

const StyledEmployeeLink = styled(EmployeeLink)`
    color: ${Styles.planierColor.blueTurquoise.textColor};
    font-size: 12px;
    line-height: 15px;

    :hover {
        text-decoration: underline;
    }
`

const isLegacyEnvironment = getIsLegacyEnvironment()

// This is not the final version. Specs still missing.
const EmployeeGroupNodeUnconnected = ({
    name,
    kpiData,
    suunnittelujakso,
    addEventEmployee,
    openListModal,
    groupedByData,
    employeeId,
    daterange,
    workunits,
    infoText,
}: IEmployeeGroupNodeProps): JSX.Element => {
    const isBaronaExternalUser = useSelector(selectIsBaronaExternalUser)

    const handleOpenFindSuitableShfitsModal = () => {
        const handleSaveShfitsToEmployee = async (itemIds: ReadonlySet<TDataSourceItemId>) => {
            // proposal event IDs come in the form `P_<eventid>_<employeeId>` so extract the event ID
            // so that the data source finds the matching events from demand calendar's data source
            const eventIds = itemIds

            await addEventEmployee(TARVENAKYMA_CALENDAR_ID, new Set(eventIds), employeeId)
        }

        openListModal({
            listIdForModal: SUITABLE_EVENTS_LIST_ID,
            modalTitle: 'workforce-scheduling.SuitableEvents.ModalTitle',
            keepModalOpenOnListRowSelect: true,
            additionalDataSourceDataRequestFiltersParameters: {
                employeeId,
                planningPeriodVersionId: suunnittelujakso?.ActiveVersionId,
                WorkUnitIds: !workunits || workunits.size === 0 ? undefined : getValueAsArray(workunits),
                StartDate: daterange?.start,
                EndDate: daterange?.end,
            },
            saveButton: {
                onClick: handleSaveShfitsToEmployee,
                label: 'workforce-scheduling.SuitableEvents.SaveButton.Label',
            },
        })
    }

    const actions: ComponentProps<typeof GroupNode>['actions'] = [
        {
            icon: 'playlist_add',
            onClick: handleOpenFindSuitableShfitsModal,
            tooltip: 'workforce-scheduling.SuitableEvents.Tooltip',
        },
    ]

    let primaryText: ReactNode = name
    let overridePrimaryTextEvenWhenGrouped = false

    if (isLegacyEnvironment) {
        if (groupedByData?.groupMainTitle) {
            primaryText = groupedByData.groupMainTitle
            overridePrimaryTextEvenWhenGrouped = true
        }

        primaryText = <StyledEmployeeLink tyontekija={{ Nimi: name, Id: employeeId }} />
    }

    return (
        <GroupNode
            actions={!isBaronaExternalUser ? actions : []}
            groupedByData={groupedByData}
            kpiData={kpiData}
            overridePrimaryTextEvenWhenGrouped={overridePrimaryTextEvenWhenGrouped}
            primaryText={primaryText}
            infoText={infoText}
        />
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    const selectedWorkunits = selectValuePickerValue(state, WORKUNIT_VALUEPICKER_ID) as Set<number>
    const selectedDateRange = selectValuePickerValue(state, CALENDAR_DATEPICKER_ID) as IDateRange

    const selectedSuunnittelujaksotSet = (selectValuePickerValue(state, PLANNING_PERIODS_VALUE_PICKER_ID) ??
        new Set<number>()) as Set<number>
    const suunnittelujakso = selectStoredOptionsOptionByPropertyMatchingValue<ISuunnittelujakso>(
        state,
        PLANNING_PERIODS_VALUE_PICKER_ID,
        'ActiveVersionId',
        [...selectedSuunnittelujaksotSet][0]
    )

    return {
        suunnittelujakso,
        daterange: selectedDateRange,
        workunits: selectedWorkunits,
    }
}

const mapDispatchToProps = {
    addEventEmployee: addEventEmployeeThunk,
    openListModal: openListModalThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeGroupNodeUnconnected)
